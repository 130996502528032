// 프로모션 페이지 - 헤더색션 히어로 색션

////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';

import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결

// 이미지 연결
import logoW from 'assets/logoW.svg';

import Feature1Video from 'assets/Promotion/overlapPromotion4.mp4';
import Feature3Image from 'assets/Promotion/PromotionImage1.png';
import template from 'assets/main/lobby/project/template.svg';

// SASS&CSS 연결
import "sass/frontPage.scss"

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function FeatureSection () {
    
    return  (
    <Fragment>
    <style jsx>{`${ComponentStyle}`}</style>
        <section id='FeatureSection'>
        <div className='FeatureContainer'>
            <div className='Textontainer'>
                <p className='FeatureText'>
                방송 화면, <br/>이렇게 쉽게 만들어보세요
                </p>
                <p className='FeatureTextDetails'>
                오버랩은 드래그 앤 드롭 방식으로 방송 화면을 편집할 수 있는 도구를 제공합니다. 마치 파워포인트를 사용하듯이 원하는 요소를 화면에 배치하고 크기를 조절할 수 있어요. 전문 지식 없이도 직관적인 조작만으로 멋진 방송 화면을 완성할 수 있어요.
                </p>
            </div>
      
            <div>
                
                <div className='exampleView'>
                    <video src={Feature1Video} width={'100%'} height={'100%'} autoPlay={true} muted loop />
                </div>
            </div>
        </div>
        <div className='FeatureContainer subContainer'>
            <div className='exampleView'>
            <img className='exampleImage' src={template} />
            </div>
            <div className='Textontainer'>
                <p className='FeatureText'>
                다양한 템플릿으로 빠르게 만들어보세요
                </p>
                <p className='FeatureTextDetails'>
                시간이 부족한가요? 오버랩은 다양한 디자인의 템플릿을 제공합니다. 원하는 템플릿을 선택하고 내용만 수정하면 빠르게 방송을 시작할 수 있습니다. 테마별로 준비된 템플릿으로 방송의 분위기를 손쉽게 연출해 보세요.
                </p>
            </div>
      
        </div>
        <div className='FeatureContainer'>
            <div className='Textontainer'>
                <p className='FeatureText'>
                다양한 위젯, <br/>여기 준비되어 있어요
                </p>
                <p className='FeatureTextDetails'>
                방송을 더욱 풍성하게 만들어 줄 다양한 위젯을 제공합니다. 텍스트 입력, 이미지 추가, 유튜브 영상 재생은 물론이고 실시간 채팅, 스코어 보드, 밴픽 등 방송에 필요한 모든 기능을 손쉽게 추가할 수 있습니다. 원하는 위젯을 선택하고 화면에 배치해 보세요.
                </p>
            </div>
            <div className='exampleView'>
            <img className='exampleImage'  src={Feature3Image} />
            </div>
        </div>
       
      

        </section>
      </Fragment>
    );

}

export default FeatureSection;



const ComponentStyle = `

#FeatureSection{
    display :flex;
    flex-direction: column;

}
.FeatureContainer{
    font-family: '프리텐다드ExtraBold';
    width : 100%;
    height : 700px;
    display :flex;
    justify-content: space-around;
    align-items: center;
}

.subContainer{
    background-color : rgb(48, 48, 48);
}
.FeatureContainer .Textontainer{
    display :flex;
    flex-direction: column;
    width : 500px;
}




.FeatureContainer .Textontainer .FeatureText{
    width : 100%;
    font-size : 50px;
}
.FeatureContainer .Textontainer .FeatureTextDetails{
    font-family: "프리텐다드SemiBold", sans-serif;
    padding-top : 20px;
    font-size : 18px;
    line-height: 28px; /* 넉넉한 줄 간격 */
    color: #e0e0e0;
}
.FeatureContainer .exampleField{
       width : 50%;
    }

.FeatureContainer .exampleView{
    width : 640px;
    height : auto;
    background-color : #606060;
        overflow : hidden;
    border-radius : 10px;
}

.exampleImage{
    display :flex;
    object-fit : cover;
    width : 100%;
    height 100%;

}

/* 너비가 1200px 이하인 경우 */
@media (max-width: 1200px) {
    .FeatureContainer{
        height : 900px;
        flex-direction: column;
        justify-content: center;
    }
   .FeatureContainer .Textontainer{
        width : 640px;
        padding-bottom : 100px;
    }
    .FeatureContainer .Textontainer .FeatureText{
        font-size : 40px;
    }
    .subContainer{
        background-color : rgb(48, 48, 48);
        flex-direction: column-reverse;
    }
}

/* 너비가 1200px 이하인 경우 */
@media (max-width: 768px) {
    .FeatureContainer{
        height : 700px;
    }
    .FeatureContainer .Textontainer{
        width : calc( 100% - 80px);
    }
    .FeatureContainer .Textontainer .FeatureText{
        font-size : 30px;
    }
    .FeatureContainer .Textontainer{
        padding-bottom : 50px;
    }
    .FeatureContainer .Textontainer .FeatureTextDetails{
        font-size : 14px;
        line-height: 22px; /* 넉넉한 줄 간격 */
    }
    .FeatureContainer .exampleView{
        width : calc( 100% - 80px);
        height : auto;
        background-color : #606060;
            overflow : hidden;
        border-radius : 10px;
    }
}
`;