////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
import { SortableContainer, SortableElement,ReactSortable } from 'react-sortablejs';
// 라이브러리 



// SASS&CSS 연결
// import "sass/edit.scss"

// 이미지 연결
import chzzkLogo from 'assets/sidebar/chat/chzzk.jpg';

// 컴포넌트 연결
// import VariableTeam from 'components/edit/editVariable/varTeam/VariableTeam';//오버레이
import AppendProfileCreate from 'components/Edit/editPanel/append/AppendProfileCreate';
import AppendSource from 'components/Edit/editPanel/append/AppendSource';

// 서비스 연결
import * as fileAPI from 'service/api/file' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function AppendCameraArea({context,panelHeight,panelWidth,modifyWidget,listOption={}}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set

    const [AreaState, setAreaState] = useState('list');
    const [TragetWidget, setTragetWidget] = useState(null);

    // state
    const [sourceComponent, setSourceComponent] = useState([]);

    const [SelectProfile, setSelectProfile] = useState(null);

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        context.getShareProfile();
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

      useEffect(() => {

        if(!TragetWidget){
          setTragetWidget(context.selectWidget)
        }
        else{
          context.panelControl('bottom','widget')
        }
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [context.selectWidget]);


      useEffect(() => {
        getSource();
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [context.sourceList]);



    // const Use
    let areaComponent = null


    const getSource = () => {
      let selectMediaSource = '';
      if(context.findwidget(context.selectWidget)){
        selectMediaSource =  context.findwidget(context.selectWidget)?.[0]?.data?.value?.mediaSource
      }
      console.log('sourcePreview',context.sourceList);
      const sourcePreview = context.sourceList.map((source)=>(
        <AppendSource sourceID={source.shareID} source={source} shareState={source.shareState} sourceLabel={source.shareLabel??'이름없는 카메라'} sourcePreview={context.sourcePreview?.[source.deviceId]?.[source.shareID]} sourceIcon={'camera'} deviceId={source.deviceId} sourceState={source.shareState} selectSource={selectSource} selectID={selectMediaSource}/>
      ))
      setSourceComponent(sourcePreview)
    }    

    const selectSource = (shareID) => {
      if (typeof context.selectWidget === 'string') {
        modifyWidget('value',['mediaSource'],[shareID],context.selectWidget)
      } else if (Array.isArray(context.selectWidget)) {
        return 'This is an array';
      } else {
        return 'This is neither a string nor an array';
      }
        context.panelControl('bottom','widget')
    }

    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <section id = 'AppendCameraArea'>
                  <div id = 'SourceList'>
                    {sourceComponent}
                  </div>
                </section>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(AppendCameraArea);
  


  const EditPageStyle = `

  #AppendCameraArea {
    display: flex;
    flex : 1 ;
    flex-direction: column;
  }

  #AppendCameraArea #ProfileContainer {
    flex : 1 ;
    display: flex;
  }

  #AppendCameraArea #SourceList{
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-template-rows: repeat(auto-fill, 250px);
    gap: 10px;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 0px 0px 5px 5px;
  }
  #AppendCameraArea .appendSource{
    width: 300px;
    height: 250px;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    background-color: #3F3F3F;
    color: #aaaaaa;
    transition : all 0.1s;
    border-radius: 5px;
  }

  #AppendCameraArea .appendSource.selected{
    width: 300px;
    height: 250px;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    background-color: #333;
    color: #aaaaaa;
    transition : all 0.1s;
    border-radius: 5px;
  }

  #AppendCameraArea .appendSource:hover{
    background-color: #333;
  }
  #AppendCameraArea .appendSource .previewArea{
    width: 300px;
    height: 200px;
    display: flex;
   justify-content: center;
    align-items: center;
  }
  #AppendCameraArea .appendSource .previewArea .sourceImage{
    position: relative;
  }

  #AppendCameraArea .appendSource .previewArea .sourceIcon{
    display: flex;
    width: 100%;
  }


  #AppendCameraArea .appendSource .PreviewImage{
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 20px) ;
    height: 180px;
    margin : 10px;
    object-fit: cover;
    border-radius : 5px;
  }

  #AppendCameraArea .appendSource .PreviewUpper{
    display: flex;
    position: absolute;
    top : 0;
    margin : 10px;
    width: calc(100% - 20px) ;
    height: 35px;
  }
  
  #AppendCameraArea .appendSource .PreviewUpper .localHostIcon{
      width: 25px;
      height: 25px;
      margin : 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius : 30px;
      background : #444;
  }

  #AppendCameraArea .appendSource .PreviewIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 20px) ;
    height: 180px;
    margin : 10px;
    background : black;
    border-radius : 5px;
  }
  #AppendCameraArea .appendSource .infoArea{
    width: calc(100% - 20px) ;
    height: 50px;
    overflow : hidden;
  }

  `;
  
