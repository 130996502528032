////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , Profiler, createContext} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import _ from 'lodash';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"


// 컴포넌트 연결

// 서비스 연결
import * as Event from 'service/event/Actions' ; 
import * as Utility from'service/other/Utility.js' ; 
import * as viewAPI from 'service/api/view' ; 
import * as spaceAPI from 'service/api/space' ; 
import * as pageAPI from 'service/api/page' ; 
import { token } from 'service/event/Account'; 
import GlobalContext from 'service/context/GlobalContext';
import { widget } from 'service/value/Model';
import { object } from 'service/event/template';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

class GlobalProvider extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
        guideBallon : false,
        onGuideBallon : this.onGuideBallon,
        offGuideBallon : this.offGuideBallon,
      };
    }

    async componentDidMount()  {

    }

    componentDidUpdate(prevProps, prevState) {

    }



    /** onGuideBallon | 오버랩을 가저 온 이후에 초기화를 진행합니다.
     * @returns 
     *  */ 
    onGuideBallon = (element,position,option) =>{
      
      console.log('onGuideBallon',element,position,option)
      
      this.setState({
        guideBallon : {element,position,option}
      })
    }

    /** offGuideBallon | 오버랩을 가저 온 이후에 초기화를 진행합니다.
     * @returns 
     *  */ 
    offGuideBallon = () =>{
      this.setState({
        guideBallon : null
      })
    }

    ////////////////////////////////////////////////////////
    // 소켓 연결 부분
    ////////////////////////////////////////////////////////

    componentWillUnmount() {
        // 방을 나갑니다.
        console.log('소켓을 종료합니다.')
    }
  
    render() {

      return (
        <GlobalContext.Provider value={this.state}> 
            {this.props.children}
        </GlobalContext.Provider>
      );
    }
  }

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

    // export default Login;
    export default GlobalProvider;