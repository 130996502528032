////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결

import GuideBalloon from 'components/common/form/GuideBalloon'// 가이드 벌룬


import ColorModule from '../editModule/ColorModule'; // 이미지 버튼을 눌러 여러 옵션중 값을 정합니다.
import SwitchModule from 'components/Edit/editModule/SwitchModule'; // boolean 요소를 선택하게 해주는 위젯입니다.
import FontModule from 'components/Edit/editModule/FontModule' // 폰트 위젯
import TimeModuleV2 from 'components/Edit/editModule/TimeModuleV2' // 폰트 위젯
import ImageButtonModule from 'components/Edit/editModule/ImageButtonModule' // 폰트 위젯

// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 
import { Pause } from '@material-ui/icons';
// 이미지 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* size: { width : number, height : number }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function TimerEditForm({target,modify,keyName,title,option}) {

/**
     * 위젯을 수정합니다.
     *  @param {bool} classify : 수정할 값의 분류를 적습니다.
     *  @param {bool} key : 수정할 키의 이름을 적습니다.
     *  @param {bool} value : 해당페이지가 선택한 뷰에서 방송중인지 확인합니다.
     */

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set
    const context = useContext(EditContext);
    const uuid = target.uuid
    const {style={},meta={},size={},value={},position={},display={},preset=null} = keyName
    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);


    const [AtStart, setAtStart] = useState(null);
    const [AtEnd, setAtEnd] = useState(0);
    const [PauseTime, setPauseTime] = useState(0);
    const [PlayTime, setPlayTime] = useState(0);
    

    const [TimerState, setTimerState] = useState('stop');

    const [ReadyTimer, setReadyTimer] = useState(false);

    const [IsBroadcast, setIsBroadcast] = useState(false);

    // rel 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        initCache();
    }, []);



    useEffect(() => {
        setIsBroadcast(ED.CheckBroadcast(context))
    }, [context.view]);


    useEffect(() => {
        console.log('캐쉬 변경')
        initCache();
    }, [context.ChannelCache]);

    const {width,height,color,fontFamily,timerType,time,endTime} = keyName ;



    useEffect(() => {
        // endTimeControl(ED.getValue(target, endTime))
        initCache();
        const intervalId = setInterval(() => liveTick(AtStart,AtEnd,PauseTime,timerType), 1000);
        return () => {clearInterval(intervalId);console.log('캐쉬 제거')}
    }, [AtStart,AtEnd,PauseTime,timerType]);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    // 해당 에디터에서 편집이 가능한 값 목록

    const initCache = () =>{
        const Cache = context.getChannelCache(target)
        if (Cache.hasOwnProperty('pauseTime')) {setPauseTime(Cache?.pauseTime)} 
        if (Cache.hasOwnProperty('timerState')) {setTimerState(Cache?.timerState)} 
        if (Cache.hasOwnProperty('atStart')) {setAtStart(Cache?.atStart)}
        if (Cache.hasOwnProperty('atEnd')) {setAtEnd(Cache?.atEnd)}
        liveTick(AtStart,AtEnd,PauseTime,timerType);
        setReadyTimer(true);
    }

    const CheckBroadcast = () =>{

        if (context.view==null){
            return null
        }
        // TODO : 이곳에서 뷰가 변경될때마다 hook 을 걸어서 해당 뷰가 갱신 된경우에 한해서 편집점이 보고 있는 페이지와 뷰가 가리키는 페이지가 일치하는지 확인합니다.
    }

    const liveTick = (AtStart,AtEnd,PauseTime,timerType) => {

        const WidgetTime = ED.getValue(target,time)
        
        switch (ED.getValue(target,timerType)) {
            case 'start':
                if(TimerState=='play') {
                    setPlayTime(WidgetTime - Math.floor((Date.now() - AtStart) / 1000))
                }
                if(TimerState=='stop') {
                    setPlayTime(WidgetTime)
                }
                if(TimerState=='pause') {
                    setPlayTime(WidgetTime - Math.floor((PauseTime-AtStart) / 1000))
                }
                break;

            case 'end':
                const now = new Date(); // 현재 시간
                const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // 오늘 자정
                const secondsElapsed =  Math.floor((now.getTime() - startOfDay.getTime()) / 1000); // 밀리초 차이를 초로 변환       

                if(TimerState=='play') {
                    setPlayTime(AtEnd-secondsElapsed)
                }
                if(TimerState=='stop') {
                    setPlayTime(AtEnd-secondsElapsed)
                }
                if(TimerState=='pause') {
                    setPlayTime(AtEnd-secondsElapsed)
                }
                break;
        
            default:
                break;
        }


    }

    const endTimeControl = (time) => {

        const now = new Date(); // 현재 시간
        const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // 오늘 자정
        const secondsElapsed = (now.getTime() - startOfDay.getTime()) / 1000; // 밀리초 차이를 초로 변환       
        
        console.log('체크1 endTimeControl',time);

        if(time >=Math.floor(secondsElapsed)){
            console.log('체크1',time)
            setAtEnd(time)
            context.setChannelCache({pauseTime:PauseTime,timerState:TimerState,atStart:AtStart,atEnd:time},target);
        }
        if(time < Math.floor(secondsElapsed)){
            console.log('체크2',time + 86400)
            setAtEnd(time + 86400)
            context.setChannelCache({pauseTime:PauseTime,timerState:TimerState,atStart:AtStart,atEnd:time+ 86400},target);
        }
    }


    const playTimer = (widgetState) => {
        switch (widgetState) {
            case 'play':
                if(TimerState == 'play'){
                    break;
                }
                if(AtStart == null){
                    setAtStart(Date.now());
                }else {

                    setAtStart(AtStart +(Date.now() - PauseTime)); // 3 초
                } 
                setPauseTime(0);
                setTimerState('play');
                console.log('플레이  >: ',Date.now(),AtStart , PauseTime, AtEnd)
                context.setChannelCache({pauseTime:0,timerState:'play',atStart:AtStart +(Date.now() - PauseTime),atEnd:AtEnd},target);
                break;
            case 'pause': // -- 일시정지 --
                // setAtStart(Date.now()+PlayTime);
                
                setTimerState('pause');
                if(AtStart == null || AtStart == 0){
                    setPauseTime(0)
                    context.setChannelCache({pauseTime:0,timerState:'pause',atStart:AtStart,atEnd:AtEnd},target);
                }else{
                    setPauseTime(Date.now())
                    context.setChannelCache({pauseTime:Date.now(),timerState:'pause',atStart:AtStart,atEnd:AtEnd},target);
                }

                break;
            case 'stop': // -- 멈춤 --
                setAtStart(null)
                setPauseTime(0);
                setTimerState('stop');
                context.setChannelCache({pauseTime:0,timerState:'stop',atStart:null,atEnd:AtEnd},target);
                break;
            default:
                break;
        }
        liveTick(AtStart,AtEnd,PauseTime,timerType)
        console.log('구해진 시간 테스트:','cache',context.getChannelCache(uuid));
    }



    const LiveControlField = () =>{


        let displayTime = PlayTime>=0?PlayTime:0;
    
        const Hours = Math.floor(displayTime / 3600)
        const Minutes = Math.floor((displayTime % 3600) / 60)
        const Seconds = displayTime % 60
        const Time = (Hours!=0?Hours+':':'')+(Minutes>=10?Minutes:'0'+Minutes)+':'+(Seconds>=10?Seconds:'0'+Seconds);

        return(
            <Fragment>
                
                <div className={!IsBroadcast?'liveControlField':'liveControlField disable'}>
                    <div className='ControlBox'>
                        <small>남은시간</small>
                        <p className={ReadyTimer?'liveTimer':'liveTimer disable'}>{Time}</p>
            
                        <ImageButtonModule value={TimerState} type ="mediaState"  data={[{val:"play",icon:"play_arrow"},{val:"pause",icon:"pause"},{val:"stop",icon:"device_reset"},]} change={(type,value)=>playTimer(value)}/>
                    </div>
                    <div className='InfoBox'>
                        <div className='infoButton'>
                        <GuideBalloon width={250} margin={'0px -20px 0px 0px'}guideText={<p style={{fontSize: 14}}>타이머의 표시된 시간을 변경합니다.<br/><small>해당 위젯이 없는 다른 페이지 송출시 초기화 됩니다.</small> </p>} direction='topright'>
                        {Editor.icon({icon:'info',size:20,lineHeight:20})}
                        </GuideBalloon>
                        </div>

                    </div>
                    {/* <div className={!IsBroadcast?'StateBox':'StateBox disable'} >
                       {Editor.icon({icon:'wifi_off',size:40,lineHeight:40})}
                       <small>송출중에만 사용 할 수 있습니다</small>
                    </div> */}

                </div>
              
            </Fragment>
            )
 

    }

    return (
    <Fragment>
        <style jsx>{`${FormStyle}`}</style>
        <section className='TimerEditForm'>
            {Editor.editorDetail('타이머','timer',OpenForm,setOpenForm,Toggle)}

            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>
                {Editor.WidgetEditSubhead({text:'시간설정',icon:"schedule"})}
                <article className="EditPart" >
                {LiveControlField()}

                {timerType? <ImageButtonModule value={ED.getValue(target,timerType)}  title={timerType?.title??"타이머 방식"} type ={timerType.key} data={[{val:"start",icon:"timer_10_alt_1"},{val:"end",icon:"event_note"}]} change={(type,value)=>modify(timerType.category,type,value,uuid)}/>: ''}

                {ED.getValue(target,timerType) == 'start' ?
                <div>
                    <hr/>
                    <TimeModuleV2 value={ED.getValue(target,time)} title={time?.title??"남은 시간"} type ={time.key} change={(type,value)=>{modify(time.category,type,value,uuid);playTimer('stop');}}/>
                    <ImageButtonModule value={ED.getValue(target,time)}  title={time?.title??"빠른설정"} type ={time.key} data={[{val:900,text:'15'},{val:600,text:'10'},{val:300,text:'5'},{val:60,text:'1'}]} change={(type,value)=>{modify(time.category,type,value,uuid);playTimer('stop');}}/>
                </div>
                :''}

                {ED.getValue(target,timerType) == 'end' ?
                <div>
                    <hr/>
                    <GuideBalloon guideText={<p> 선택한 시간에 맞춰서 타이머가 종료됩니다. </p>} direction='topleft'>
                    <TimeModuleV2 value={ED.getValue(target,endTime)} title={endTime?.title??"알람 시간"} type ={endTime.key} change={(type,value)=>{modify(endTime.category,type,value,uuid);endTimeControl(value)}}/>
                    </GuideBalloon>
                    {/* <ImageButtonModule value={ED.getValue(target,time)}  title={time?.title??"빠른설정"} type ={time.key} data={[{val:900,text:'15'},{val:600,text:'10'},{val:300,text:'5'},{val:60,text:'1'}]} change={(type,value)=>{modify(time.category,type,value,uuid);playTimer('stop');}}/> */}
                </div>
                :''}
                <GuideBalloon guideText={<p> 페이지 시작과 동시에 <br/> 타이머가 시작 됩니다. </p>} direction='topleft'>
                    <SwitchModule value={1} title="자동재생" type ="useFill" change={(type,value)=>(console.log('테스트'))}/>
                </GuideBalloon>
                </article>
                
                {Editor.WidgetEditSubhead({text:'스타일 설정',icon:"category"})}
                <article className="EditPart" >
                {fontFamily? <FontModule value={ED.getValue(target,fontFamily)} title={fontFamily?.title??"폰트"} type ={fontFamily.key} change={(type,value)=>modify(fontFamily.category,type,value,uuid)}/> : ''}
                {color?<ColorModule value={ED.getValue(target,color)} type={color?.key} title={color?.title??"색상"} change={(type,value)=>modify(color?.category,type,value,uuid)}/>:''}
                </article>
            </div>
        </section>
    </Fragment>
    );

}

export default TimerEditForm;


const FormStyle = `

    .TimerEditForm .liveControlField{
        width: calc ( 100% - 6px );
        display: flex;
        background-color : #383838;
        padding : 10px 0px;
        margin-bottom : 10px;
        border-radius : 5px;
   
        box-shadow: 3.5px 3.5px 10px #252525, -3.5px -3.5px 10px #535353;
        transition: all 1s;
        position: relative;
    }


    .TimerEditForm .liveControlField .disable{
        filter:grayscale(50%);

    }

    .TimerEditForm .liveControlField .ControlBox{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }
    .TimerEditForm .liveControlField .InfoBox{
        position: absolute;
        z-index : 1;
        top:5px;
        right : 5px;

    }

    .infoButton{
        position: absolute;
        right : 0;
        top : 0;
    }

    .TimerEditForm .liveControlField .StateBox{
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        z-index : 2;
        background-color : rgba(0,0,0,0.5);
        border-radius : 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: opacity 1s;

    }

    .TimerEditForm .liveControlField .StateBox.disable{
        opacity : 0;
        pointer-events: none;
    }

    .TimerEditForm .liveTimer{

        opacity: 1;
    }
    .TimerEditForm .liveTimer.disable{
        opacity: 0;
    }


`;