////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
// import Show from '../show/Show'; // 메뉴 헤더

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function RangeModule({value,type,step=1,min,max,title='범위 조정',change}) {


    return(
        <div className="EditModule RangeModule V1">
            <style jsx>{`${ModuleStyle}`}</style>
            <p className="editNav" >{title?title:'범위 조정'}</p>
                <div className="controlField">

                    <div className="controlInput">
                        <input type="range" className="rangeInput" name="range" min={min} step={step} max={max} value={value} onChange={(e) => change(type,e.target.value)} />
                    </div>


                    <div className="ValueInfo">
                        {value}
                    </div>

                 

                {/* <input type="range" className="rangeInput" name="volume" min={this.props.min} step={step} max={this.props.max} value={this.props.value} onChange={(e) => this.props.change(this.props.type,e.target.value)} /> */}

                {/* {(this.props.endImage?<span className='endImage'><img src={this.props.endImage}/></span>:'')} */}
                </div>
        </div>

    )

}


// class RangeModule extends Component {

//     render() { 
   
//         let step = 1
//         if(this.props.step){
//             step = this.props.step
//         }
         
//         return (
//             <label className="EditModule RangeModule" htmlfor="fname"> 
//                 <p className="editNav" >{this.props.title?this.props.title:'색상'}</p>
//                 <div className="valueView">
                    
//                 {(this.props.startImage?<span className='startImage'><img src={this.props.startImage}/></span>:'')}
         
//                 <span>
//                     {(this.props.valueView?this.props.value+''+this.props.valueView+' ':'')}
//                 </span>
//                 <input type="range" className="rangeInput" name="volume" min={this.props.min} step={step} max={this.props.max} value={this.props.value} onChange={(e) => this.props.change(this.props.type,e.target.value)} />

//                 {(this.props.endImage?<span className='endImage'><img src={this.props.endImage}/></span>:'')}
//                 </div>

//             </label>
//         );
//     }
// }

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(RangeModule);
  


const ModuleStyle = `
    .RangeModule.V1 .controlField{
        display : flex;
        align-items: center;
        justify-content: end;
    }

    .RangeModule.V1 .controlField .ValueInfo{
        margin-left : 10px;
        background-color : #333;
        width : 26px;
        height : 26px;
        display : flex;
        align-items: center;
        justify-content: center;
        border-radius : 5px;
        text-align : center
    }

    .RangeModule.V1 .controlField .controlInput{
    
    }

    /* Range Input 기본 스타일 초기화 */
    .RangeModule.V1 .controlField .controlInput .rangeInput {
        -webkit-appearance: none; /* 기본 스타일 제거 (Webkit 기반 브라우저) */
        width: 60px;
        height: 8px;
        border-radius: 5px;
        background: #d3d3d3;
        outline: none;
        transition: background 0.3s ease;
    }
    /* Range Input 호버 시 배경색 변경 */
    .RangeModule.V1 .controlField .controlInput .rangeInput:hover {
        background: #c0c0c0;
    }

    /* Range Input 활성화 시 배경색 변경 */
    .RangeModule.V1 .controlField .controlInput .rangeInput:active {
        background: #a0a0a0;
    }

    /* Webkit 기반 브라우저용 Thumb 스타일링 */
    .RangeModule.V1 .controlField .controlInput .rangeInput::-webkit-slider-thumb {
        -webkit-appearance: none; /* 기본 스타일 제거 */
        appearance: none;
        width: 16px;
        height: 16px;
        background: #ffffff;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        transition: background 0.3s ease, transform 0.3s ease;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
        margin-top: -4px; /* Thumb을 트랙 중앙에 위치시키기 위해 */
    }

    /* Webkit 기반 브라우저 Thumb 호버 시 스타일 변경 */
    .RangeModule.V1 .controlField .controlInput .rangeInput::-webkit-slider-thumb:hover {
        background: #999;
        transform: scale(1.1);
    }

    /* Webkit 기반 브라우저 Thumb 활성화 시 스타일 변경 */
    .RangeModule.V1 .controlField .controlInput .rangeInput::-webkit-slider-thumb:active {
        background: #999;
        transform: scale(1.2);
    }

    /* Webkit 기반 브라우저 Track 스타일링 */
    .RangeModule.V1 .controlField .controlInput .rangeInput::-webkit-slider-runnable-track {
        height: 8px;
        cursor: pointer;
        background: #d3d3d3;
        border-radius: 5px;
    }

    /* Firefox용 Thumb 스타일링 */
    .RangeModule.V1 .controlField .controlInput .rangeInput::-moz-range-thumb {
        width: 20px;
        height: 20px;
        background: #999;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        transition: background 0.3s ease, transform 0.3s ease;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    }

    /* Firefox Thumb 호버 시 스타일 변경 */
    .RangeModule.V1 .controlField .controlInput .rangeInput::-moz-range-thumb:hover {
        background: #999;
        transform: scale(1.1);
    }

    /* Firefox Thumb 활성화 시 스타일 변경 */
    .RangeModule.V1 .controlField .controlInput .rangeInput::-moz-range-thumb:active {
        background: #999;
        transform: scale(1.2);
    }

    /* Firefox Track 스타일링 */
    .RangeModule.V1 .controlField .controlInput .rangeInput::-moz-range-track {
        height: 8px;
        cursor: pointer;
        background: #d3d3d3;
        border-radius: 5px;
    }

    /* IE 및 Edge용 Thumb 스타일링 */
    .RangeModule.V1 .controlField .controlInput .rangeInput::-ms-thumb {
        width: 20px;
        height: 20px;
        background: #999;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        transition: background 0.3s ease, transform 0.3s ease;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    }

    /* IE 및 Edge Thumb 호버 시 스타일 변경 */
    .RangeModule.V1 .controlField .controlInput .rangeInput::-ms-thumb:hover {
        background: #999;
        transform: scale(1.1);
    }

    /* IE 및 Edge Thumb 활성화 시 스타일 변경 */
    .RangeModule.V1 .controlField .controlInput .rangeInput::-ms-thumb:active {
        background: #999;
        transform: scale(1.2);
    }

    /* IE 및 Edge Track 스타일링 */
    .RangeModule.V1 .controlField .controlInput .rangeInput::-ms-track {
        height: 8px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    .RangeModule.V1 .controlField .controlInput .rangeInput::-ms-fill-lower {
        background: #d3d3d3;
        border-radius: 5px;
    }

    .RangeModule.V1 .controlField .controlInput .rangeInput::-ms-fill-upper {
        background: #d3d3d3;
        border-radius: 5px;
    }
  `;
