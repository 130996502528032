////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { SortableContainer, SortableElement,ReactSortable } from 'react-sortablejs';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import PositionModule from '../editModule/PositionModule'; // 위젯의 사이즈를 조정 하는 모듈입니다.
import ErrorModule from '../editModule/ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.
import GuideBalloon from 'components/common/form/GuideBalloon'// 가이드 벌룬


import ScoreboardModule from '../editModule/ScoreboardModule'; // 스코어 보드용 팀 묘듈
import BanPickModule from '../editModule/BanPickModule'; // 스코어 보드용 팀 묘듈
import SentenceModule from '../editModule/SentenceModule'; // 텍스트 모듈

import TextSizeModule from '../editModule/TextSizeModule'; // 텍스트 크기 모듈
import FontModule from '../editModule/FontModule'; // 폰트 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import SwitchModule from '../editModule/SwitchModule'; // 체크박스 모듈
import NumberModule from '../editModule/NumberModule'; // 숫자 모듈
import ImageButtonModule from 'components/Edit/editModule/ImageButtonModule' // 폰트 위젯

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 
import * as DeCoder from 'service/engine/DeCoder'; 
// 이미지 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* style: { x : number, y : number }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function BanPickEditForm({target,modify,keyName,title,option}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set
    const context = useContext(EditContext);
    const uuid = target.uuid
    const alias = target.alias;
    const {style={},meta={},size={},value={},position={},display={},preset=null} = keyName
    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);

    const [ShowList, setShowList] = useState(false);

    const [NowTargetPoint, setNowTargetPoint] = useState(false);
    // const [ShowList, setShowList] = useState(false);
    
    const [IsBroadcast, setIsBroadcast] = useState(false);

    const [BanPickState, setBanPickState] = useState('stop');

    // rel 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        initCache();
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    useEffect(() => {
        setIsBroadcast(ED.CheckBroadcast(context))
    }, [context.view]);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

       // 해당 에디터에서 편집이 가능한 값 목록
       const {infoFont,banPickRule,teamData,asset} = keyName ;


       const AssetList = DeCoder.getAsset({target});

       const TargetAsset = ED.getValue(target,asset)
       const TempAsset = AssetList.filter((preset) => preset.name == TargetAsset);

       const NowAsset = TempAsset?.[0];


    const initCache = () =>{
        // playBanPick();
        // const Cache = context.getChannelCache(target?.uuid)
        // if (Cache.hasOwnProperty('pauseTime')) {setPauseTime(Cache?.pauseTime)} 
        // if (Cache.hasOwnProperty('timerState')) {setTimerState(Cache?.timerState)} 
        // if (Cache.hasOwnProperty('atStart')) {setAtStart(Cache?.atStart)}
        // if (Cache.hasOwnProperty('atEnd')) {setAtEnd(Cache?.atEnd)}
        // liveTick(AtStart,AtEnd,PauseTime,timerType);
        // setReadyTimer(true);
    }

    const playBanPick = () => {
        const Cache = context.getChannelCache(target);
        if(Cache.step==null||Cache.step==undefined){
            context.setChannelCache({step:0},target);
        }
    }

    const stopBanPick = () => {
        context.resetChannelCache({},target);
    }

    const selectTarget = (asset) => {
        context.setChannelCache({selectTarget:asset},target);
        setNowTargetPoint(asset)
    }

 
    /**
     * 포커스 된 에셋 을 선택합니다.
     * @returns 
     */

    const submitTarget = () => {

        const Cache = context.getChannelCache(target);
        const Target = Cache?.selectTarget; 
        const List = Cache?.banPickList??{'leftBan':[],'leftPick':[],'rightBan':[],'rightPick':[],}; 
        
        if(!Target||Cache?.step==undefined||Cache?.step==null){return}

        const Rule =  ED.getValue(target,banPickRule);

        if(Rule.length <= Cache?.step){return}

        const Rule_Team  = Rule?.[Cache.step]?.team
        const Rule_Type  = Rule?.[Cache.step]?.type
                
        if(Rule_Team=='left'&&Rule_Type=='ban'){
            List['leftBan'].push({...Target,index:Cache?.step})
        }
        else if(Rule_Team=='left'&&Rule_Type=='pick'){
            List['leftPick'].push({...Target,index:Cache?.step})
        }
        else if(Rule_Team=='right'&&Rule_Type=='ban'){
            List['rightBan'].push({...Target,index:Cache?.step})
        }
        else if(Rule_Team=='right'&&Rule_Type=='pick'){
            List['rightPick'].push({...Target,index:Cache?.step})
        }

        context.setChannelCache({selectTarget:null,banPickList:List,lastTarget:Target,step:Cache.step+1},target);
        setNowTargetPoint(null)
    }

    const prevTarget = () => {
        const Cache = context.getChannelCache(target);       
        if(!Cache?.step){return}
        
        if(Cache?.banPickList){
            const List = Cache?.banPickList;

            const PrevStep  = Cache?.step-1??Infinity;

            const LeftBan = Cache.banPickList.leftBan??[];
            const LeftPick = Cache?.banPickList?.leftPick??[];
            const RightBan = Cache?.banPickList?.rightBan??[];
            const RightPick = Cache?.banPickList?.rightPick??[];

            const FilteredLeftBan = LeftBan.filter(item => item.index+1 <= PrevStep);
            const FilteredLeftPick = LeftPick.filter(item => item.index+1 <= PrevStep);
            const FilteredRightBan = RightBan.filter(item => item.index+1 <= PrevStep);
            const FilteredRightPick = RightPick.filter(item => item.index+1 <= PrevStep);
            
            List['leftBan'] = FilteredLeftBan
            List['leftPick'] = FilteredLeftPick
            List['rightBan'] = FilteredRightBan
            List['rightPick'] = FilteredRightPick

            context.setChannelCache({selectTarget:null,banPickList:List,step:PrevStep},target);
            setNowTargetPoint(null)
        }
     
    }

    



    const getAssetElement = () =>{

        return(
            <Fragment>
            <div class="tailArea">
                <div class="tail"/>
            </div>
            {NowAsset.length != 0 ?
            <div className="AssetSelectList"  onClick={(e)=> e.stopPropagation()} >
            {NowAsset?.data.map((data,index) => (
            <div className='AssetList' onClick={()=>selectTarget(data)} >
                      {data?.image?
                      <img className='assetImage' src={data?.image}/>:
                      <div className='noImage'> {Editor.icon({icon:'account_circle',size:25,lineHeight:30})} </div>
                      }
                      <p>{data?.nameKo}</p>
                    </div>
            ))}
            </div>
            :
            <div className='AssetSelectMessage '>
                <p> 생성된 팀이 없습니다.</p>
            </div>
            }
            </Fragment>
        )
    }



    /**
     * 밴픽 순서를 표시해주고 이동을 도와주는 컴포넌트를 만듭니다. 
     * @returns 
     */
    const getRoleIndex = () => {


        const Role = ED.getValue(target,banPickRule)
        const Cache = context.getChannelCache(target)
        const RoleBox = Role.map((item,index)=> {

            let option = 'RoleBox ';

            if(item?.type == 'ban'){
                option = option + 'ban '
            }
            else if(item?.type == 'pick'){
                option = option + 'pick '
            }
        
            if(Cache?.step==undefined){

            }else if(Cache?.step>index){
                option = option + 'prev '
            }else if(Cache?.step==index){
                option = option + 'focus '
            }else if(Cache?.step<index){
                option = option + 'next '
            }
            
            return(
                <div className={option}>
                {index+1}
                </div>
            )
            
        });


        RoleBox.push(
            <div className={'RoleBox'}>
                {Editor.icon({icon:'exit_to_app',size:20,lineHeight:30})} 
            </div>
        )
        const DefaultMargin = 90;
        const LeftMargin =  (Cache?.step??0) * -32;
       
        return (
                <div className='RoleList' style={{marginLeft: DefaultMargin+LeftMargin}}>
                    {RoleBox}
                </div>
                );

    }

    /**
     * 포커스된 타켓을 보여주는 컴포넌트를 만듭니다.
     * @returns 
     */
    const getFocusTarget = () => {


        const Cache = context.getChannelCache(target)

        if(NowTargetPoint){
            
            if(!NowTargetPoint?.image){
                // 포커스된 에셋에 이미지가 없으면 임시 이미지가 표시됩니다.
                return( <div className='targetImageNULL'> {Editor.icon({icon:'account_circle',size:40,lineHeight:50})} </div>)
            }else{
                // 포커스된 에셋에 이미지가 있으면 대상의 이미지가 표시됩니다.
                return( <img className='targetImage' src={NowTargetPoint?.image}/>)
            }
        }
        // 포커스된 에셋이 없으면 이미지가 표시됩니다.
        else{
            return (<div className={Cache?.step==null?'targetReady':'targetNULL'}> {Editor.icon({icon:'how_to_reg',size:40,lineHeight:50})} </div>)
        }
    }

    const buttonAction = (mode) =>{
        switch (mode) {
            case 'play':
                playBanPick()
                setBanPickState('play');
                setShowList(true);
                setNowTargetPoint(false)
                break;
            case 'stop':
                stopBanPick()
                setBanPickState('stop');
                setShowList(false);
                setNowTargetPoint(false)
                break;
        
            default:
                break;
        }

    }

    const LiveControlField = () =>{
        const Cache = context.getChannelCache(target);
        
        return(
            <Fragment>
                
                <div className={!IsBroadcast?'liveControlField':'liveControlField disable'}  onClick={()=>setShowList(false)}>
                    <div className='ControlBox'>
                        <div className='BanPickStateArea'>
                            <small>밴픽 상태</small>
                            <ImageButtonModule value={BanPickState} type ="mediaState"  data={[{val:"play",icon:"play_arrow"},{val:"stop",icon:"device_reset"},]} change={(type,value)=>buttonAction(value)}/>
                        </div>
                        {Cache?.step!=undefined? 
                         <div className='BanPickIndexArea'>
                         <small>밴픽 순서</small>
                             <div className='BanPickInfoBox'>
                                 {getRoleIndex()}
                                 <div className='BanPickInfoBoxHover'>
                                     <div  className='leftIndex'>
                                     {/* {Editor.icon({icon:'arrow_back_ios',size:20,lineHeight:30})}  */}
                                     </div>
                                     <div  className='rightIndex'>
                                     {/* {Editor.icon({icon:'arrow_forward_ios',size:20,lineHeight:30})}  */}
                                     </div>
                                 </div>
                             </div>
                         </div>
                        : 
                        <div className='BanPickIndexArea'>
                            <p className='BanPickIndexAreaText'> {Editor.icon({icon:'play_arrow',size:25,lineHeight:30})} &nbsp; 버튼을 눌러 시작하세요 </p>
                        </div>
                        
                        }
                       
                        <div className='BanPickSelectBox'>
                            <div className='BanPickSelectArea' >
                            <div className='BanPickPrev' >
                                <button onClick={(e)=>{prevTarget(); e.stopPropagation()}}>
                                <GuideBalloon guideText={<p> 전 밴픽을 취소합니다. </p>}  width={150} direction='top'>
                                {Editor.icon({icon:'arrow_back_ios',size:30,lineHeight:50,color:(Cache?.step?'#ffffff':'#999999')})} 
                                </GuideBalloon>
                                </button>
                            </div>
                            <div className='BanPickTarget'  onClick={(e)=>{setShowList(true); e.stopPropagation();}}>
                            {getFocusTarget()}
                            </div>
                            <div className={NowTargetPoint?'BanPickNext ready':'BanPickNext'} >
                            <button onClick={(e)=>{submitTarget(); e.stopPropagation()}}>
                                <GuideBalloon guideText={<p> 선택을 확정합니다. </p>}  width={150} direction='top'>
                                {Editor.icon({icon:'arrow_forward_ios',size:30,lineHeight:50,color:(NowTargetPoint?'#ffffff':'#999999')})} 
                                </GuideBalloon>
          
                                </button>
                            </div>
                            </div>
                            {ShowList?getAssetElement():null}
                        </div>
                    </div>
                    {/* <div className={!IsBroadcast?'StateBox':'StateBox disable'} >
                       {Editor.icon({icon:'wifi_off',size:40,lineHeight:40})}
                       <small>송출중에만 사용 할 수 있습니다</small>
                    </div> */}

                </div>
              
            </Fragment>
            )
 

    }

    return (
    <Fragment>
        <style jsx>{`${FormStyle}`}</style>
        <section className='BanPickEditForm'>
            {Editor.editorDetail('밴픽','how_to_reg',OpenForm,setOpenForm,Toggle)}
            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>


                <article className="EditPart">
                {LiveControlField()}
                </article>


            {Editor.WidgetEditSubhead({text:'공통 설정',icon:"stylus"})}
                <article className="EditPart">
                {infoFont? <FontModule value={ED.getValue(target,infoFont)} title={infoFont?.title??"팀 폰트"} type={infoFont.key} change={(type,value)=>modify(infoFont.category,type,value,uuid)}/>: ''}
                {infoFont? <FontModule value={ED.getValue(target,infoFont)} title={infoFont?.title??"밴픽 폰트"} type={infoFont.key} change={(type,value)=>modify(infoFont.category,type,value,uuid)}/>: ''}

                </article>
                
            </div>
        </section>
    </Fragment>
    );

}

export default BanPickEditForm;


const FormStyle = `

.BanPickEditForm {

}

.BanPickEditForm .teamEdit{

}
.BanPickEditForm  .scoreboardNav{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BanPickEditForm  .scoreboardNav .scoreboardButtons{
    display: flex;

}
.BanPickEditForm .liveControlField{
    width: calc ( 100% - 6px );
    display: flex;
    background-color : #383838;
    padding : 10px 0px;
    margin-bottom : 10px;
    border-radius : 5px;

    box-shadow: 3.5px 3.5px 10px #252525, -3.5px -3.5px 10px #535353;
    transition: all 1s;
    position: relative;
}


.BanPickEditForm .liveControlField .disable{
    filter:grayscale(50%);

}

.BanPickEditForm .liveControlField .ControlBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.BanPickEditForm .liveControlField .InfoBox{
    position: absolute;
    z-index : 1;
    top:5px;
    right : 5px;

}

.BanPickEditForm .liveControlField .BanPickStateArea{
    width : 210px;
    height : 60px;
    margin-bottom : 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.BanPickEditForm .liveControlField .BanPickIndexArea{
    width : 210px;
    height : 60px;
    overflow-x : hidden;
    border-radius : 5px;
    background-color: #222;
    box-shadow: 3.5px 3.5px 10px #252525, -3.5px -3.5px 10px #535353;

}
.BanPickEditForm .liveControlField .BanPickIndexArea small{
    width : 210px;
    height : 25px;
    display: flex;
    justify-content: center;
    line-height : 30px;
    text-align : center;
}
.BanPickEditForm .liveControlField .BanPickInfoBox{
    width : 210px;
    height : 30px;
    margin-bottom :5px;
    display: flex;
    position: relative;
}
.BanPickEditForm .liveControlField .BanPickInfoBoxHover{
    width : 210px;
    height : 30px;
    display: flex;
    position: absolute;
    z-index : 2;
    justify-content: space-between;
}
.BanPickEditForm .liveControlField .BanPickInfoBoxHover .leftIndex{
    width : 50px;
    height : 30px;
    display: flex;
    justify-content: center;
    background: linear-gradient(to left, transparent, #222, #222);
}
.BanPickEditForm .liveControlField .BanPickInfoBoxHover .rightIndex{
    width : 50px;
    height : 30px;
    display: flex;
    justify-content: center;
    background: linear-gradient(to right,  transparent, #222, #222);
}

.BanPickEditForm .liveControlField .BanPickIndexArea .BanPickIndexAreaText{
    display: flex;
    width : 100%;
    height : 30px;
    justify-content: center;
    line-height : 30px;
    margin : 15px 0px;
    text-align : center;
}

.BanPickEditForm .liveControlField .RoleList{
    display: flex;
    transition: margin 1s;
}
.BanPickEditForm .liveControlField .RoleBox{
    display: flex;
    width: 20px;
    height : 20px;
    border : 1px solid transparent;
    margin: 5px;
    font-size: 15px;
    align-items: center;
    justify-content: center;

}
.BanPickEditForm .liveControlField .RoleBox.ban.prev{
    background-color : red;
    border : 1px solid red;
}
.BanPickEditForm .liveControlField .RoleBox.ban.focus{
    border : 1px solid red;
    background-color : red;
    animation: BanEditForm_fadeInOut 3s ease-in-out infinite;
}
.BanPickEditForm .liveControlField .RoleBox.ban.next{
    border : 1px solid red;
}
.BanPickEditForm .liveControlField .RoleBox.pick.prev{
    background-color : blue;
    border : 1px solid blue;
}
.BanPickEditForm .liveControlField .RoleBox.pick.focus{
    border : 1px solid blue;
    animation: PickEditForm_fadeInOut 3s ease-in-out infinite;
}
.BanPickEditForm .liveControlField .RoleBox.pick.next{
    border : 1px solid blue;
}

@keyframes PickEditForm_fadeInOut {
    0%, 100% {
        background-color : transparent;
    }
    50% {
        background-color : blue;
    }
}
@keyframes BanEditForm_fadeInOut {
    0%, 100% {
        background-color : transparent;
    }
    50% {
        background-color : red;
    }
}
/* 밴픽 */

.BanPickEditForm .liveControlField .BanPickSelectBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.BanPickEditForm .liveControlField .BanPickSelectBox .BanPickSelectArea {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.BanPickEditForm .liveControlField .BanPickSelectBox .BanPickSelectArea .BanPickPrev {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height : 100%;
}

.BanPickEditForm .liveControlField .BanPickSelectBox .BanPickSelectArea .BanPickNext {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height : 100%;
}

.BanPickEditForm .liveControlField .BanPickSelectBox .BanPickSelectArea .BanPickNext.ready {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height : 100%;
}


.BanPickEditForm .liveControlField .BanPickSelectBox .BanPickTarget{
    display: flex;
    justify-content : center;
    align-items : center;
    margin : 10px;
    width: 80px;
    height : 80px;
    border-radius : 10px;
    background-color: #444;
    border-radius: 5px;
    box-shadow: 3px 3px 3px #222;
    overflow: hidden;
    outline : none;
    position: relative;
}

.BanPickEditForm .liveControlField .BanPickSelectBox .targetImage{
    width: 80px;
    height : 80px;
    object-fit: cover;
}

.BanPickEditForm .liveControlField .BanPickSelectBox .targetImageNULL{

}

.BanPickEditForm .liveControlField .BanPickSelectBox .targetReady{

}
.BanPickEditForm .liveControlField .BanPickSelectBox .targetNULL{
    animation: targetNULLfadeIn 1s ease-in-out ;
}
.BanPickEditForm .liveControlField .BanPickSelectBox .targetSelect{
    position: absolute;
}


@keyframes targetNULLfadeIn {
    0%{
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
  }

  
.BanPickEditForm  .tailArea{
    width: 100%;
    height : 20px;
    display: flex;
    justify-content: center;
}
.BanPickEditForm  .tailArea .tail{
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 20px 20px 20px; /* 삼각형 모양 */
    border-color: transparent transparent #222 transparent; /* 아래쪽 삼각형 */
}

.BanPickEditForm  .AssetSelectList{
  width: 100%;
  overflow: auto;
  height: 200px;
  background-color: #222;
  display: grid;
  grid-template-columns: repeat(auto-fill, 55px); /* 그리드 컬럼 크기를 60px로 설정 */
  box-sizing: border-box;
  justify-content: center; /* 그리드 컨테이너 내에서 아이템들을 중앙 정렬 */
  gap: 5px; /* 그리드 아이템 간 갭을 10px로 설정 */
  padding: 10px 5px; /* 컨테이너 패딩 설정 */
}

.BanPickEditForm .AssetSelectMessage{
  width: 100%;
  display: flex;
  overflow: auto;
  justify-content: center;
  align-items: center;
  height : 60px;
  background-color: #222;
}

.BanPickEditForm .AssetList{
  position: relative;
  width: 55px;
  height : 55px;
  background-color: #333;
  overflow: hidden;
}

.BanPickEditForm .liveControlField  .AssetList .assetImage{
  width: 55px;
  height : 55px;
  object-fit: cover;
}

.BanPickEditForm .AssetList .noImage{
  width: 55px;
  height : 30px;
  padding-top : 5px;
  display:flex;
  justify-content : center;
  align-items : center;

}
.BanPickEditForm .AssetList p{
  width: 100%;
  height : 20px;
  position: absolute;
  bottom : 0px;
  font-size : 10px;
  display :flex;
  justify-content : center;
  align-items : center;
  transition: all 0.3s ;
  font-family: "프리텐다드Black", sans-serif;
  text-shadow: 1px 1px 1px black;
}
.BanPickEditForm .AssetList:hover p{
  overflow: hidden;
  width: 100%;
  height : 100%;
  font-size : 15px;
  bottom : 0px;
  backdrop-filter: saturate(180%) blur(20px);
}

.BanPickEditForm .liveControlField .StateBox{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    z-index : 2;
    background-color : rgba(0,0,0,0.5);
    border-radius : 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 1s;

}

.BanPickEditForm .liveControlField .StateBox.disable{
    opacity : 0;
    pointer-events: none;
}

`;



const TempScoreboardData = [
    {
        teamName : 'team1',
        teamColor : '#ff0000',
        teamLogo : '',
        score :  0,
        subScore : 0, 
    },    {
        teamName : 'team2',
        teamColor : '#ffff00',
        teamLogo : '',
        score :  1,
        subScore : 1, 
    }
] 