////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';
import { useHistory,useLocation } from 'react-router-dom';
// [리덕스]스토어 연결

// 이미지 연결
import logoW from 'assets/logoW.svg';
import PromotionVideo from 'assets/Promotion/overlapPromotion1.mp4';

// 컴포넌트 연결
import SharePanel from 'components/share/SharePanel'; // 오버랩 인트로 색션
import OverviewSection from 'components/Promotion/OverviewSection'; // 중요한 내용 색션
import BottomSection from 'components/Promotion/BottomSection'; // 바텀 부분 색션
import FeatureSection from 'components/Promotion/FeatureSection'; // 오버랩의 특징 설명 색션

import AccountBanner from 'components/Promotion/AccountBanner'; // 어카운트 관련 배너
// SASS&CSS 연결
import "sass/frontPage.scss"



// [프로바이더]
import ShareProvider from 'service/provider/ShareProvider';

// 서비스 연결
import * as Editor from 'service/edit/EditService' ; 
import { forEach } from 'lodash';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


function RTCSharePage () {

    // const [devicesList, setDevices] = useState([]);
    // const [screenList, setScreen] = useState([]);

    // const [StreamList, setStreamList] = useState({});
    // const [selectedDeviceId, setSelectedDeviceId] = useState('');
    // const videoRef = useRef(null);

    const params = new URLSearchParams(window.location.search);
    const spaceID = params.get('uuid');
    return  (
    <Fragment>
    <style jsx>{`${PageStyle}`}</style>
      <ShareProvider spaceID={spaceID} >
        <div id = "RTCSharePage">
            <SharePanel/>
        </div>
        </ShareProvider>
    </Fragment>
    );

}

export default RTCSharePage;



const PageStyle = `

#RTCSharePage{
    display :flex;
    flex-direction: column;
    width : 100vw;
    height : 100vh;
    background-color: #737373;
    font-family: '프리텐다드Mideum';
    justify-content : center;
    align-items : center;

}

`;