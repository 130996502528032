////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import AdminModuleUserList from "pages/lobby/space/sm_admin/AdminModuleUserList"
import AdminModuleSpaceList from "pages/lobby/space/sm_admin/AdminModuleSpaceList"
import AdminModuleUserDetail from "pages/lobby/space/sm_admin/AdminModuleUserDetail"
import AdminModuleUserDashboard from "pages/lobby/space/sm_admin/AdminModuleUserDashboard"
// import AdminModuleUserList from "pages/lobby/space/sm_admin/AdminModuleUserList"


// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as notice from 'service/api/notice' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility';
import * as view from 'service/api/view';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function AdminModuleUserWrap() {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [FieldRoute, setFieldRoute] = useState('dashboard'); // 'dashboard' 대시보드 'account' 계정 'accountDetail' 계정 상세 정보 'space' 스페이스 'spaceDetail' 스페이스 디테일

    const [selectID, setSelectID] = useState(null); 
    const [Postcategory, setPostcategory] = useState(null); 
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

    const setSelectUser = (id) =>{

        if(id == null){
            setFieldRoute('account')
            setSelectID(null)
        }else{
            console.log('setSelectUser',id)
            setFieldRoute('accountDetail')
            setSelectID(id)
        }

    }
   
    const getNav = () => {

        return(
        <div  className='TabHeader'>
        <div className='TabTitle'>
        계정 관리
        </div>

        <nav className='TabNav'>
            <div className='TabLeft'>
                <div className={ FieldRoute == 'dashboard' ? 'TabItem TabSelect' : 'TabItem' } onClick={()=>setFieldRoute('dashboard')}>
                    대시보드
                </div>
                <div className={ FieldRoute == 'account' || FieldRoute == 'accountDetail' ? 'TabItem TabSelect' : 'TabItem' } onClick={()=>setFieldRoute('account')}>
                    계정
                </div>
                <div className={ FieldRoute == 'space' ? 'TabItem TabSelect' : 'TabItem' } onClick={()=>setFieldRoute('space')}>
                    스페이스
                </div>
            </div>

            <div className='TabRight'>

            </div>
    
        </nav>
            
        </div>
        )
    }

    const getContent = () => {

        switch (FieldRoute) {
            case 'dashboard':
                return <AdminModuleUserDashboard/>
            case 'account':
                return <AdminModuleUserList setSelectUser={setSelectUser}/>
            case 'accountDetail':
                return <AdminModuleUserDetail setSelectUser={setSelectUser} selectID={selectID} />
            case 'space':
                return <AdminModuleSpaceList setFieldRoute={setFieldRoute}/>
            default:
                return <AdminModuleUserDashboard/>
        }
    }
    

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    
    return(
        <Fragment>
            <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
            <div id = "AdminModuleUserWrap">
            {getNav()}
            {getContent()}
            </div>
        </Fragment>
    )
  }

  export default AdminModuleUserWrap;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `
    .AdminModule {

    }

`;

const ContainerStyle = `
   #AdminModuleUserWrap{
        display: flex;
        margin : 0px 20px;
        width : 100%;
        flex-direction: column;
    }


    .navField{
        display: flex;
        width : 100%;
        flex-direction: column;
    }

    .TabHeader{
        display flex;
        height : 104px;
        width : 100%;
    }
    

    .TabTitle{
        display flex;
        width : 100%;
        height : 50px;
        font-size : 25px;
    }
    
    .TabNav{
        display : flex;
        flex-direction: row;
        width : 100%;
        height : 50px;
        border-bottom : 4px solid #999;
        justify-content: space-between;
    }
    .TabLeft{
        display : flex;
        justify-content: start;
    }
    .TabRight{
        display : flex;
        justify-content: end;
    }

    .TabItem{
        display : flex;
        width : 100px;
        padding : 5px 0px;
        height : 40px;
        align-items: center;
        justify-content: center;
        color : #999;
    }

    .TabAction{
        display : flex;
        width : 100px;
        padding : 5px 0px;
        height : 40px;
        align-items: center;
        justify-content: center;
        color : #999;
    }
    
    .TabSelect{
        color : #fff;
        border-bottom : 4px solid #fff;
    }
    
    .post_preview{
        display : flex;
        flex-direction: column;
        width : 100%;
        height : 100px;
        margin : 20px 0px;
        border-bottom : 1px solid #999;
    }

    .post_preview:hover{
        background-color : #333;
    }

        
    .post_header{
        display : flex;
        width : 100%;
        height : 30px;
        justify-content: space-between;
    }
    
    .post_content{
        display : flex;
        width : 100%;
        font-size : 15px;
        line-height : 20px;
        height : 60px;
        overflow : hieddn;
    }

    .post_left{
        display : flex;
        width : 50%;
        align-items: center;
        justify-content: start;
        align-items: end;
    }
    .post_right{
        display : flex;
        width : 30%;
        align-items: center;
        justify-content: end;
        align-items: end;
    }

    .post_title{
        display : flex;
        width : 100%;
        flex-direction: row;
        font-size : 25px;
        line-height : 30px;
        height : 30px;
        justify-content: space-between;
    }
    
`;

