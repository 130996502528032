////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';

import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결

// 이미지 연결
import logoW from 'assets/logoW.svg';
import PromotionVideo from 'assets/Promotion/overlapPromotion1.mp4';

// 컴포넌트 연결

import OverviewSection from 'components/Promotion/OverviewSection'; // 중요한 내용 색션
import BottomSection from 'components/Promotion/BottomSection'; // 바텀 부분 색션
import FeatureSection from 'components/Promotion/FeatureSection'; // 오버랩의 특징 설명 색션

import AccountBanner from 'components/Promotion/AccountBanner'; // 어카운트 관련 배너
// SASS&CSS 연결
import "sass/frontPage.scss"
// 서비스 연결
import * as Editor from 'service/edit/EditService' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


function ShareNav ({shareType,setShareType}) {

    const [devices, setDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState('');
    const videoRef = useRef(null);

    const [detailOption, setDetailOption] = useState(false);

    return  (
    <Fragment>
    <style jsx>{`${ComponentStyle}`}</style>
        <nav id = "ShareNav">
        
            <div id = "ShareNavType">
                <div className={shareType=='video' ? 'selectType ShareNavOption': 'ShareNavOption'} onClick={()=>{setShareType('video')}}>
                    <div className='optionIcon'>
                    {Editor.icon({icon:'videocam',size:20,lineHeight:30,color:(shareType=='video' ? '#fff':'#999')})}
                    </div>
                    <div className='optionTitle'>
                    카메라
                    </div>
                </div>
                <div className={shareType=='mic' ? 'selectType ShareNavOption': 'ShareNavOption'}  onClick={()=>{setShareType('mic')}}>
                    <div className='optionIcon'>
                    {Editor.icon({icon:'mic',size:20,lineHeight:30,color:(shareType=='mic' ? '#fff':'#999')})}
                    </div>
                    <div className='optionTitle'>
                    마이크
                    </div>
                </div>
                <div className={shareType=='screen' ? 'selectType ShareNavOption': 'ShareNavOption'}  onClick={()=>{setShareType('screen')}}>
                <div className='optionIcon'>
                    {Editor.icon({icon:'screen_share',size:20,lineHeight:30,color:(shareType=='screen' ? '#fff':'#999')})}
                    </div>
                    <div className='optionTitle'>
                    화면
                    </div>
                </div>
            </div>

            <div id = "ShareNavSetting">
                <div className='ShareNavOption' onClick={()=>setDetailOption(!detailOption)}>
                    {Editor.icon({icon:'more_horiz',size:20,lineHeight:30})}
                </div>
            </div>
            <div id = "ShareOption" className={detailOption == false?'displayNone':''}>
                <div className='shareOptionDetail'> 
                    <div className='MenuIcon'>
                        {Editor.icon({icon:'videocam_off',size:15,lineHeight:20,color:"#adadad"})}
                    </div>
                    <div className='MenuText' style={{color:"#adadad"}}>
                        {'1'}
                    </div>
                </div>
                <div className='shareOptionDetail'> 
                    <div className='MenuIcon'>
                        {Editor.icon({icon:'more_horiz',size:15,lineHeight:20,color:"#adadad"})}
                    </div>
                    <div className='MenuText' style={{color:"#adadad"}}>
                        {'1'}
                    </div>
                </div>
                <div className='shareOptionDetail'> 
                    <div className='MenuIcon'>
                        {Editor.icon({icon:'more_horiz',size:15,lineHeight:20,color:"#adadad"})}
                    </div>
                    <div className='MenuText' style={{color:"#adadad"}}>
                        {'연결중지'}
                    </div>
                </div>
            </div>
        </nav>

    </Fragment>
    );

}

export default ShareNav;



const ComponentStyle = `


#ShareNav{
    max-width : 600px;
    height : 50px;
    background-color: #2F2F2F;
    display: flex;
    flex-direction: row;
    color : #fff;
    position: absolute;
    z-index : 10;
    bottom : 50px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
}

#ShareNav #ShareNavType{
    height : 50px;
    flex : 1;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

#ShareNav #ShareNavSetting{
    width : 50px;
    height : 50px;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

#ShareNav .ShareNavOption{
    width : 100px;
    height : 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding : 0px 20px;
    border-radius: 5px;
    color : #999;
}

#ShareNav .ShareNavOption.selectType{
    color : #fff;
    background-color: #333;
}

#ShareNav .ShareNavOption .optionIcon {
    width : 30px;

}

#ShareNav .ShareNavOption .optionTitle {
    width : 60px;
       
}

#ShareNav .ShareNavOption:hover{
    background-color: #333;
}

#ShareNav #ShareOption{
    width : 150px;
    position: absolute;
    background: #2F2F2F;
    bottom: 60px;
    right: 0;
    border-radius: 5px;
    color : #999;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
}

#ShareNav #ShareOption.displayNone{
    display :none;
}

#ShareNav #ShareOption .shareOptionDetail{
    width : 100%;
    height : 50px;
    display : flex;
    justify-content: center;
    align-items: center;
}


#ShareNav #ShareOption .shareOptionDetail {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    
    #ShareNav #ShareOption .shareOptionDetail hr {
      border: none;      /* 경계선 제거 */
      height: 0;         /* 높이 초기화 */
      color: transparent; /* 색상을 투명하게 설정 */
      background-color: transparent; /* 배경 색상을 투명하게 설정 */
      border : 1px solid #2C2C2C;
    }

    #ShareNav #ShareOption .shareOptionDetail {
      width: 130px;
      height : 20px;
      display : flex;
      margin : 5px;
      padding : 5px;
      border-radius: 10px;
    }

    #ShareNav #ShareOption .shareOptionDetail:hover{
      background-color: #2C2C2C;
    }

    #ShareNav #ShareOption .shareOptionDetail .MenuIcon{
      width: 20px;
      height : 20px;
      display : flex;
      overflow :hidden;
      justify-content : center;
      align-items : center;
    }

    #ShareNav #ShareOption .shareOptionDetail .MenuText{
      width: 90px;
      margin-left : 10px;
      height : 20px;
      display : flex;
      overflow :hidden;
      justify-content : start;
      align-items : center;
    }

/* 미디어 쿼리: 600px 이하일 때 */
@media (max-width: 600px) {

    #ShareNav .ShareNavOption{
        width : 30px;
        height : 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding : 0px 20px;
        border-radius: 5px;
        color : #999;
        
    }
    #ShareNav .ShareNavOption.selectType{
        color : #fff;
        background-color: #333;
    }

    #ShareNav .ShareNavOption .optionIcon {
        width : 30px;
        text-align :center;

    }

    #ShareNav .ShareNavOption .optionTitle {
        width : 0px;
        overflow: hidden;
        
    }
}
`;