////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , Profiler, createContext} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';

// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"


// 컴포넌트 연결
import EditerLoding from 'components/Edit/other/EditerLoding';//오버레이
// 서비스 연결
import * as deviceService from 'service/device/deviceService' ; 
import * as Event from 'service/event/Actions' ; 
import * as View from 'service/api/view' ; 
import * as Utility from'service/other/Utility.js' ; 
import * as overlapAPI from 'service/api/overlap' ; 
import * as spaceAPI from 'service/api/space' ; 
import * as viewAPI from 'service/api/view' ; 
import { token } from 'service/event/Account'; 
import EditContext from 'service/context/EditContext';
import { widget } from 'service/value/Model';
import { identity } from 'lodash';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

class EditProvider extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        sidebar : 'Screen',
        extendEditor: null,
        overlap : [],
        updateTime : null,
        loading : false,
        overlapToken : null,
        selectPageIndex : null,
        selectWidget : null,
        selectPageUUID : null,
        cryptoIP : null,
        spaceId : this.props.spaceId,
        spaceUUID : this.props.spaceUUID,
        clipBoard : {},
        action : null,
        test : 0,
        zoom : 0,
        update : 0,
        editor : {
            zoom : 0,
            width : 0,
            height : 0,
        },
        panel : {
            preferences : {state:'',option:{}},
            utility : {state:'widget',option:{}},
            bottom : {state:'widget',option:{}},
        },
        drop : {
            type: null,
            target : null,
        },
        view : null,
        viewList : [],
        channelCache : {},
        sourceList : [],
        PeerList : {},
        sourcePreview : {},
        mouse : {
            x : 0,
            y : 0,
        },
        contextMenu : null, 
        addPage: this.addPage,
        modifyPage : this.modifyPage,
        modifyOverlap : this.modifyOverlap,
        setGlobalVariable : this.setGlobalVariable,
        findwidget : this.findwidget,
        modifyWidget : this.modifyWidget,
        nowPage: this.getEditPage,
        selectPage: this.selectPage,
        deletePage: this.deletePage,
        dropControl : this.dropControl,
        modifySelectWidget : this.modifySelectWidget,
        editResolution : this.editResolution,
        getPageList: this.getPageList,
        showContext: this.showContext,
        hideContext: this.hideContext,
        appendClipBoard: this.appendClipBoard,
        mousePosition : this.mousePosition,
        getChannelList : this.getChannelList,
        selectView : this.selectView,
        setView : this.setView,
        setAction : this.action,
        panelControl :  this.panelControl,
        setChannelCache : this.setChannelCache,
        resetChannelCache : this.resetChannelCache,
        requestSharePeer : this.requestSharePeer,
        sandAnswer : this.sandAnswer,
        sendIce : this.sendIce,
        reNameChannelCache : this.reNameChannelCache,
        getChannelCache : this.getChannelCache,
        getShareProfile : this.getShareProfile,
        setExtendEditor : this.setExtendEditor,
    };
      this.overlapSocket  = overlapAPI.start();
      this.viewSocket  = viewAPI.start();
      this.spaceSocket  = spaceAPI.start();
      this.deviceID = deviceService.getID()
    }

    async componentDidMount()  {

        this.overlapSocket.emit('join', {
            uuid : this.props.uuid,
            id : this.props.overlapID,
            accountToken : token('account'), 
        });
      
        this.overlapSocket.on('get_token', (token) => {
            if(token){
                this.setState({
                    overlapToken : token,
                },()=>{this.getOverlap()})
            }
        });

        this.overlapSocket.on('connect', (token) => {
            console.log('Connected to the server');
        });

        this.overlapSocket.on('disconnect', (token) => {
            console.log('Disconnected from the server');
        });

        this.overlapSocket.on('reconnecting', (attemptNumber) => {
            console.log(`Reconnecting to the server (attempt ${attemptNumber})`);
        });


        this.overlapSocket.on('modify_page', (page) => {
            console.log('modify_page',page);
            this.modifyLoaclPage(page);
        });

        this.overlapSocket.on('append_page', ({page,order}) => {
            console.log('page,order',page,order)
            this.modifyLoaclPage(page,{order});
        });


        this.overlapSocket.on('get_overlap', (overlapData) => {
            this.setState({
                overlap : overlapData,
                loading : true
            },()=> this.afterGetOverlap())
            const initUUID = overlapData?.content?.page[0]?.uuid??null;
            if(initUUID){
                this.selectPage(initUUID)
            }
        });

        this.overlapSocket.on('modify_overlap', (overlapData) => {
            console.log('modify_overlap',overlapData);
            this.responseModifyOverlap(overlapData)
        });

        this.viewSocket.on('receive_view', (viewData) => {
            this.setState({
                view:viewData
            })
        });  

        this.viewSocket.on('connect',() =>  {
            this.initView();
            console.log('view 소켓 연결 완료',);
        });
        
        this.viewSocket.on('disconnect', () =>  {
            console.log('view 소켓 연결 종료');
        });

        this.viewSocket.on('receive_channelCache', (cache) =>  {

            this.setState({
                channelCache: cache,
            });
        });

        this.spaceSocket.emit('join', {
            uuid : this.state.spaceUUID,
            deviceID : this.deviceID,
            accountToken : token('account'),  
        });
        
        /** 스페이스 소켓과 연결된 경우 초기화 정의 */
        this.spaceSocket.on('ready_space', (data) => {
            this.spaceSocket.emit('getShareList', {
                uuid : this.state.spaceUUID,
            });
          });

        this.spaceSocket.on('shareList', (data) => {
            console.log('shareList 테크 테스트',data?.shareList)
            const ShareList  = data?.shareList??[];
            const cryptoIP  = data.cryptoIP;
            const result = [];
            for (const [deviceId, deviceInfoArray] of Object.entries(ShareList)) {
                if (Array.isArray(deviceInfoArray)) {
                    deviceInfoArray.forEach(deviceInfo => {

                
                    console.log('deviceInfo?.cryptoIP?.[0]',deviceInfo?.cryptoIP?.[0],cryptoIP)

                    result.push({
                        local : cryptoIP && cryptoIP == deviceInfo?.cryptoIP?.[0],
                        deviceId,
                        ...deviceInfo
                    });
                    });
                } else {
                    console.error(`Expected an array but got ${typeof deviceInfoArray} for deviceId: ${deviceId}`);
                }
            }

            this.setState({
                sourceList : result,
                cryptoIP : cryptoIP,
            })
          });

        this.spaceSocket.on('response_shareProfile', (data) => {
            this.setState({
                sourcePreview : data
            })            
        });

        this.spaceSocket.on('receiveOffer', (data) => {
            const timeStamp = Date.now();
            const NewPeerState = {timeStamp,peerState: 'offer', offer: data.target.SDP, ice : null ,endPoint:data.endPoint}
            this.setState((prevState) => ({
                PeerList: {
                  ...prevState.PeerList, // 기존 객체 복사
                  [data.target.widgetID]: NewPeerState,
                },
            }));
        });

        this.spaceSocket.on('receiveIce', (data) => {
            const timeStamp = Date.now();
            const NewPeerState = {timeStamp,peerState: 'ice', offer: null, candidate : data.target.candidate ,endPoint:data.endPoint}
            this.setState((prevState) => ({
                PeerList: {
                  ...prevState.PeerList, // 기존 객체 복사
                  [data.target.widgetID]: NewPeerState,
                },
            }));
        });

        this.spaceSocket.on('requestFail', (data) => {
            const timeStamp = Date.now();
            console.log('실패 requestFail')
            const NewPeerState = {timeStamp,peerState: 'fail',}
            this.setState((prevState) => ({
                PeerList: {
                  ...prevState.PeerList, // 기존 객체 복사
                  [data.target.widgetID]: NewPeerState,
                },
            }));
        });

          
        // viewSocket
        // this.viewSocket.emit('join', {
        //     uuid : this.props.uuid,
        //     id : this.props.overlapID,
        //     accountToken : token('account'), 
        // });

        // this.viewSocket.on('ready_view', () => {
        //     this.viewSocket.emit('request_view', {
        //         uuid : this.props.uuid,
        //         id : this.props.overlapID,
        //         accountToken : token('account'), 
        //     });
        // });

        // this.viewSocket.on('receive_view', (viewData) => {
        //     console.log('[]서버에서 값 가저옴',viewData);
        //     this.setState({
        //         viewData:viewData
        //     })
        // });

    }
    

    ////////////////////////////////////////////////////////
    // 소켓 연결 부분
    ////////////////////////////////////////////////////////

    initOverlap = () => {


    }

    /** afterGetOverlap | 오버랩을 가저 온 이후에 초기화를 진행합니다.
     * @returns 
     *  */ 
    afterGetOverlap = async () =>{

        // 페이지를 순서에 따라 정렬합니다. 
        // this.getPageList()
        const ViewList = await this.getChannelList();

        const channel = this.state.overlap?.meta?.default_channel;

        const Default = ViewList.find( (obj) => {
            return obj.uuid === channel;
        });

        if(Default){
            this.selectView(Default.uuid)
        }

    }

    getOverlap = () => {
        this.overlapSocket.emit('get_overlap', {
            id : this.props.overlapID,
            token : this.state.overlapToken, 
        });
        this.setState({})
    }

    /** addPage | 새로운 페이지를 추가합니다.
     * @param {number} index 페이지가 생성될 위치 
     * @param {Object} initData 페이지가 생성될 위치 
     * @returns 오버랩을 생성합니다. 생성 성공시 생성한 오버랩의 아이디를 반환 합니다.
     *  */ 
    addPage = (index,initData) => {

        // 기본 데이터가 있으면 기본 데이터로 만들어집니다. 
        let pageContent = {}
        if(initData != undefined){
            pageContent['page_option'] = initData?.page_option??[];
            pageContent['page_title'] = initData?.page_title??'';
            pageContent['page_summary'] = initData?.page_summary??'';
            pageContent['page_widget'] = initData?.page_widget??[];
        }

        const page_uuid = Utility.uuidMake();
        //console.log('add_page',page_uuid, this.props.overlapID,initData,index)

        const overlapOrder = Utility.deepCopy(this.state.overlap?.content?.order);

        // 페이지가 생성될 위치(index)는 마지막 페이지 범위를 넘어서면 안됩니다. 또 위치가 정해지지 않았다면
        index = index > overlapOrder.length||index==null? overlapOrder.length: index;
        overlapOrder.splice(index+1,0,page_uuid)
        
        this.overlapSocket.emit('add_page', {
            uuid : page_uuid,
            id : this.props.overlapID,
            token : this.state.overlapToken, 
            initData:pageContent,
            order: overlapOrder,
            
        });
        // this.setOverlapOrder('append',page_uuid,index);
    }

    /** deletePage | 선택한 페이지를 삭제 합니다.
     * @param {number} no 오버랩을 생성한 사람의 아이디
     * @returns 오버랩을 생성합니다. 생성 성공시 생성한 오버랩의 아이디를 반환 합니다.
     *  */ 

    deletePage = (uuid) =>{

        let content = Utility.deepCopy(this.state.overlap?.content);
        let newPage = content.page.filter((value, index, arr) => {
            return value.uuid != uuid;
        });
        content.page = newPage
        let newOverlap = this.state.overlap
        newOverlap.content =  content;


        let targetIndex = this.state.selectPageIndex
        if(uuid == this.state.selectPageUUID){
            const NextTarget = this.state.overlap.content.order.length<this.state.selectPageIndex+1 ? null:this.state.selectPageIndex+1;
            if(NextTarget!=null){
                this.selectPage(this.state.overlap.content.order[NextTarget])
            }
            targetIndex= NextTarget ;
        }


         // 페이지가 삭제된 후 선택된 페이지가 이동 될 곳을 정합니다.
  
        // 먼저 페이지 이동시키는건 어떨지
        // selectPage()
        // console.log('NextTarget',NextTarget);
        
        this.setState({
            overlap : newOverlap
        })
        this.overlapSocket.emit('delete_page', {
            page_uuid : uuid,
            token : this.state.overlapToken, 
        });

        const overlapOrder = Utility.deepCopy(this.state.overlap?.content?.order);
        const deleteOverlapOrder = overlapOrder.filter(item => item !== uuid);
        this.modifyOverlap({'content':{'order':deleteOverlapOrder}});
    }

    /**
     * getEditPage 선택된 페이지를 가저옵니다.
     */

   getEditPage = () =>{

    if(this.state.selectPageIndex == -1  || this.state.selectPageIndex == null){
        return null
    }
    return Utility.deepCopy(this.state.overlap?.content?.page[this.state.selectPageIndex]);
   }
   
   history = () =>{

   }

   initOverlapOrder = () =>{
    const overlapOrder = this.state.overlap?.content?.order;
    const overlapPages = this.state.overlap?.content?.page;
    const uuidList = overlapPages.map(page => page.uuid);

    // 페이지에는 있으나 오더에는 존재 하지 않는 UUID 인 경우
    const unknownPage = [];
    // 오더에는 있으나 존재하지 않는 페이지의 UUID 인 경우
    const unknownOrder = [];


    for (const uuid of uuidList) {
        if (!overlapOrder.includes(uuid)) {
            unknownPage.push(uuid);
        }
    }

    for (const uuid of overlapOrder) {
        if (!uuidList.includes(uuid)) {
            unknownOrder.push(uuid);
        }
    }

    // 없으면 이제 생성해    

    if(unknownPage.length!=0){
        const appendOverlapOrder = [...overlapOrder,...unknownPage]
        this.modifyOverlap({'content':{'order':appendOverlapOrder}});
    }

    if(unknownOrder.length!=0){
        const deleteOverlapOrder = overlapOrder.filter(item => unknownOrder.includes(item));
        this.modifyOverlap({'content':{'order':deleteOverlapOrder}});
    }

   }

   /**
    * setOverlapOrder 순서를 정의 합니다. 
    * @param {*} type 
    * @param {*} uuid 
    * @param {*} index 
    */
   setOverlapOrder = (type,uuid,index=null) =>{
        // this.initOverlapOrder();
        const overlapOrder = Utility.deepCopy(this.state.overlap?.content?.order);
        index = index>overlapOrder.length||index==null? overlapOrder.length: index;

        // TODO : 정상적으로 생성되나, 왼쪽 사이드바의 화면 구성
        // const isEqual = newOrderList.length === overlapOrder.length && newOrderList.every((value, index) => value == overlapOrder[index]);
        switch (type) {
            // case 'append':
            //     // overlapOrder.splice(index,0,uuid) 
            //     // console.log('OrderList',uuid,overlapOrder,index,overlapOrder.length)               
            //     // this.modifyOverlap({'content':{'order':overlapOrder}});
            //     break;
            case 'delete':
                const deleteOverlapOrder = overlapOrder.filter(item => item !== uuid);
                this.modifyOverlap({'content':{'order':deleteOverlapOrder}});
                break;
        }
   }





   ModifyLocalOverlap = (OBJ_overlap) =>{

    const TargetOverlap = Utility.deepCopy(this.state.overlap); 
    if(Object.keys(OBJ_overlap).includes('title')){
        TargetOverlap['title'] = OBJ_overlap.title

    }

    if(Object.keys(OBJ_overlap).includes('summary')){
        TargetOverlap['summary'] = OBJ_overlap.summary

    }

    if(Object.keys(OBJ_overlap).includes('meta')){
        const reviseMeta = TargetOverlap.meta
        if(Object.keys(OBJ_overlap.meta).includes('default_channel')){
            reviseMeta['default_channel'] = OBJ_overlap.meta.default_channel;
        }
        if(Object.keys(OBJ_overlap.meta).includes('tag')){
            reviseMeta['tag'] = OBJ_overlap.meta.tag;
        }
        if(Object.keys(OBJ_overlap.meta).includes('private')){
            reviseMeta['private'] = OBJ_overlap.meta.private;
        }
        TargetOverlap['meta'] = reviseMeta;

    }

    // 오버랩 글로벌을 수정합니다.
    if(Object.keys(OBJ_overlap).includes('global')){
        const reviseGlobal = TargetOverlap.global
        if(Object.keys(OBJ_overlap.global).includes('variable')){
            reviseGlobal['variable'] = OBJ_overlap.global.variable;
        }
        if(Object.keys(OBJ_overlap.global).includes('widget')){
            reviseGlobal['widget'] = OBJ_overlap.global.widget;
        }
        TargetOverlap['global'] = reviseGlobal;
    }

    // 오버랩 글로벌을 수정합니다.
    if(Object.keys(OBJ_overlap).includes('content')){
        const reviseContent = TargetOverlap.content
        if(Object.keys(OBJ_overlap.content).includes('asset')){
            reviseContent['asset'] = OBJ_overlap.content.asset;
        }
        if(Object.keys(OBJ_overlap.content).includes('order')){
            reviseContent['order'] = OBJ_overlap.content.order;
        }
        TargetOverlap['content'] = reviseContent;
    }

    // OrderList
    this.setState({
        overlap : TargetOverlap,
        update : this.state.update + 1
    })

   }
   /**
    * 오버랩을 수정합니다.
    * @param {overlap} overlapData : 수정된 오버랩 
    * 수정 가능요소 {meta : default_channel,tag, }
    */
   requsetModifyOverlap = (overlapData) => {
    this.overlapSocket.emit('update_overlap', {
        modified : overlapData,
        id : this.props.overlapID,
        token : this.state.overlapToken, 
    });

    this.ModifyLocalOverlap(overlapData)   
   }


    /**
    * 소켓 통신을 통해 받은 데이터로 수정합니다.
    * @param {overlap} overlapData : 수정된 오버랩 
    * 수정 가능요소 {meta : default_channel,tag, }
    */
   responseModifyOverlap = (overlapData) => {
    this.ModifyLocalOverlap(overlapData)   
   }

   modifyOverlap = (modifiedOverlap) => {
        this.requsetModifyOverlap(modifiedOverlap);
   }

    ////////////////////////////////////////////////////////
    // 에디터 확장 
    ////////////////////////////////////////////////////////




    /** setExtendEditor | 에디터를 확장합니다. null 이면 기존 상태로 돌아옵니다.
     * @param {string} mode 선택한 요소의 종류
     * @returns 프로바이더 내 state contextMenu 에 파라미터를 등록합니다
     *  */ 

    setExtendEditor = (mode) =>{
        this.hideContext()
        this.setState({
            extendEditor :mode
        })
    }

    ////////////////////////////////////////////////////////
    // 컨텍스트 메뉴
    ////////////////////////////////////////////////////////

    /** showContext | 컨텍스트 메뉴를 표시합니다.
     * @param {Component} target 선택한 요소의 UUID 
     * @param {string} type 선택한 요소의 종류
     * @param {number} posX 컨텍스트 메뉴가 표시될 가로 위치 (px)
     * @param {number} posY 컨텍스트 메뉴가 표시될 세로 위치 (px)
     * @returns 프로바이더 내 state contextMenu 에 파라미터를 등록합니다
     *  */ 

    showContext = (target,type,posX,posY,uuid='1') =>{
        this.setState({
            contextMenu : {target,type,posX,posY,uuid}
        })
    }

    /** hideContext | 컨텍스트 메뉴를 숨깁니다.
     * @returns 프로바이더 내 state contextMenu 값을 null 로 바꿉니다
     *  */ 
    hideContext = () =>{
        if(this.state.contextMenu != null){
            this.setState({
                contextMenu : null
            }) 
        }

    }

    ////////////////////////////////////////////////////////
    // 사용자 컨트롤
    ////////////////////////////////////////////////////////
    
    dropControl = (type = null,target,data) => {

        console.log('dropControl',type,target,data)
      

        switch (type) {
            case 'addWidget':
                this.setState({
                    drop : {
                        type : 'addWidget' , 
                        target : target,
                        data:data,
           
                    },
                    selectWidget : null,
                }) 
                break;
            case 'addFile':
                this.setState({
                    drop : {
                        type : 'addFile' , 
                        target : target,
                        data:data,
            
                    },
                    selectWidget : null,
                }) 
                break;        
            default:
                this.setState({
                    drop : null
                }) 
                break;
        }
    }
    

    ////////////////////////////////////////////////////////
    // 위젯 관련
    ////////////////////////////////////////////////////////

    findwidget = (widgetKey,type='uuid') => {
        const NowPage = this.getEditPage();

        if(!Array.isArray(widgetKey)){
            let widgetArray = [widgetKey];
            widgetKey = widgetArray;
        }
        if(type == 'uuid'){
            const target =  NowPage.page_widget.filter(function(widget) {
                // return widget.uuid === widgetKey;
                return Utility.deepCopy(widgetKey.includes(widget.uuid));
            });
            if(target.length == 0){
                console.error(`⛔️ ${target.length} 개의 ${widgetKey} 를 가진 위젯을 찾았습니다. `);
                return null;
            }
            return target; 
        }
        else if(type == 'index'){
            try {
                return Utility.deepCopy(NowPage.page_widget[Number(widgetKey)]);
            } 
            catch (error) {
                console.error(`⛔️ ${widgetKey} 번째 위젯을 찾을 수 없습니다. `);
                return null;
            }
        }
        else{
            console.error(`⛔️ ${type} 은 위젯을 찾는 기준으로 사용 할 수 없습니다. `);
            return null;
        }

    }

    /** modifyWidget | 오버랩 단일 위젯을 교체합니다.
     * @param {Widget} widget 오버랩의 PAGE 리스트를 받습니다. 
     * @param {string} modifyIog 수정한 로그을 확인합니다.
     * @returns 오버랩을 생성합니다. 생성 성공시 생성한 오버랩의 아이디를 반환 합니다.
     *  */ 
    modifyWidget = (widget,modifyIog=null) => {

        modifyIog = modifyIog? modifyIog: widget.uuid;
        
        const TargetPage = this.getEditPage();
       
        const WidgetList = TargetPage.page_widget;

        const ModifyWidgetList = WidgetList.map(item => {
        return item.uuid === widget.uuid ? widget : item;
        });
        
        console.log('ModifyWidgetList',ModifyWidgetList);
        this.modifyPage({'page_widget':ModifyWidgetList},modifyIog)
    }


    ////////////////////////////////////////////////////////
    // 페이지 수정
    ////////////////////////////////////////////////////////

    /** pageSort | 페이지의 위짓을 수정합니다.
     * @param {object} pageList 오버랩의 PAGE 리스트를 받습니다. 
     * @param {Array} order 오버랩의 Order 를 받습니다 | uuid 배열을 기반으로 해당 배열로 정렬합니다.
     * @returns 오버랩을 생성합니다. 생성 성공시 생성한 오버랩의 아이디를 반환 합니다.
     *  */ 
    pageSort = (pageList,order) =>{
          let newPageList = pageList;
          newPageList.sort((prev,next)=>{
            const aIndex = order.indexOf(prev.uuid);
            const bIndex = order.indexOf(next.uuid);
            return aIndex - bIndex;
          })
          return newPageList;
    }
    /** verifyOrder | 오버랩 파일 안에 순서 배열과 가지고 있는 페이지가 일치하는지 확인합니다.
     * @param {object} pageList 오버랩의 PAGE 리스트를 받습니다. 
     * @param {Array} order 오버랩의 Order 를 받습니다 | uuid 배열을 기반으로 해당 배열로 정렬합니다.
     * @returns 오버랩을 생성합니다. 생성 성공시 생성한 오버랩의 아이디를 반환 합니다.
     *  */ 
    verifyOrder = (pageList,order) =>{
        // 기존 order 에 등록 되지 않은 페이지를 추가합니다.
        let apeendOrder = [];
        // 기존 order 에 등록 되었으나 존재하지 않은 페이지 리스트를 만듭니다.
        let availablePageUUID = [];
        // 페이지는 존재하나 order 에 존재하지 않는 목록을 order 에 추가합니다.
        pageList.forEach((page)=>{
            availablePageUUID.push(page.uuid);
            if(order==undefined){
                order = []
            }

            if(page.uuid&&!order.includes(page.uuid)){
                apeendOrder.push(page.uuid);
            }
        });
        // 페이지는 존재하나 order 에 존재하지 않는 목록을 order 에 추가합니다.
        let delOrder = order.filter(uuid => availablePageUUID.includes(uuid));

        const VERIFYORDER = delOrder.concat(apeendOrder)

        return VERIFYORDER;
    }


     /** getPageList | 페이지의 리스트를 반환합니다.
     * @param {object} pageList 오버랩의 PAGE 리스트를 받습니다. 
     * @param {Array} order 오버랩의 Order 를 받습니다 | uuid 배열을 기반으로 해당 배열로 정렬합니다.
     * @returns 오버랩을 생성합니다. 생성 성공시 생성한 오버랩의 아이디를 반환 합니다.
     *  */ 
    getPageList = () =>{
        const VERIFYORDER = this.verifyOrder(this.state.overlap?.content?.page,this.state.overlap?.content?.order)
        if(!Utility.arrayEqual(this.state.overlap.content.order,VERIFYORDER)){
            console.warn(`⛔️ 오버렙 페이지와 순서가 일치하지 않습니다. 자동 보정을 시도합니다.`);
            let newOverlap = this.state.overlap
            newOverlap.content.order = VERIFYORDER;
            this.setState({
                overlap : newOverlap
            })
        }else{
    
        }
        const pageSort = this.pageSort(this.state.overlap?.content?.page,VERIFYORDER)

        return {page : pageSort, order : VERIFYORDER};

    }

    ////////////////////////////////////////////////////////
    // 위젯 수정
    ////////////////////////////////////////////////////////

    verifyPage = (pageData) => {

        for (let item of pageData['page_widget'] ) {
            if (Array.isArray(item)) {
                console.error('페이지가 배열 안에 배열 처럼 비 정상적인 형태를 띄고 있습니다',pageData);
              return false ; // [[]] 와 같은 경우 저장하지 않음
            }
          }
        return true; 

    }

    /** modifyPage | 페이지의 위젯을 수정합니다.
     * @param {object} modifiedData 수정할 데이터
     * @returns 오버랩을 생성합니다. 생성 성공시 생성한 오버랩의 아이디를 반환 합니다.
     *  */ 
   modifyPage = (modifiedData,modifiedType,option={}) =>{

    
        let newOverlap = Utility.deepCopy(this.state.overlap);
      
        let modifyPage  = this.getEditPage();

        const pageUUid = option?.pageUUid;
        // uuid 가 있으면 특정한 페이지의 UUID를 가저옵니다.
        if(option?.pageUUid){
            modifyPage  = this.getPage(pageUUid);
        }

        // 변경할 페이지를 찾을 수 없으면 페이지 수정을 중단 합니다.
        if(!modifyPage){
            console.error('EditProvider : 편집 대상 페이지를 찾을 수 없습니다.')
            return
        }
        
        // 기존 페이지와 수정사항을 병합 합니다.
        if(Object.keys(modifiedData).includes('page_title')){
            modifyPage['page_title'] = modifiedData['page_title'] 
        }
        if(Object.keys(modifiedData).includes('page_summary')){
            modifyPage['page_summary'] = modifiedData['page_summary'] 
        }
        if(Object.keys(modifiedData).includes('page_widget')){
            modifyPage['page_widget'] = modifiedData['page_widget'] 
        }
        if(Object.keys(modifiedData).includes('page_option')){
            modifyPage['page_option'] = modifiedData['page_option'] 
        }
        
        // Page의 UUID를 특정하면 해당 페이지를 수정합니다.
        if(pageUUid){
            const index = newOverlap.content.page.findIndex(page => page.uuid === pageUUid);
            newOverlap.content.page[index] = modifyPage;
        }
        // Page의 UUID를 특정하지 않으면 편집중인 페이지를 수정합니다.
        else{
            newOverlap.content.page[this.state.selectPageIndex] = modifyPage;
        }
        this.setState({
            overlap : newOverlap,
            updateTime :Date.now(),
        }, () => {
            if (option?.callBack) {
              option.callBack();
            }
          })

        this.overlapSocket.emit('update_page', {
            page : modifyPage,
            id : this.props.overlapID,
            token : this.state.overlapToken, 
        });
        // 이곳에 오버랩 새로운거
   }


    ////////////////////////////////////////////////////////
    // 글로벌 관련
    ////////////////////////////////////////////////////////

    setGlobalVariable = (key,value) => {

        const variable =  Utility.deepCopy(this.state.overlap?.global?.variable??null);
        if(variable == null){
            console.error(`⛔️ 오버랩 내 변수를 변경하려고 하였으나 오버랩이 아직 로딩되지 않았습니다.`);
            return 
        }

        variable[key] = value

        this.modifyOverlap({'global':{'variable':variable}});
        this.overlapSocket.emit('request_page', {
            uuid : this.state.overlap?.content?.page[this.state.selectPageIndex]?.uuid,
            token : this.state.overlapToken, 
        });
    }   

    ////////////////////////////////////////////////////////
    // 뷰
    ////////////////////////////////////////////////////////

    getChannelList = async (callBack=null) => {
        const ViewList = await View.list(Number(this.state.spaceId));
        this.setState({
            viewList : ViewList
        })
        if(callBack){
            callBack();
        }
        return ViewList
    }

    selectView = async (uuid) =>{
        // 채널 목록을 가저옵니다.
        const ViewList = await this.getChannelList();
        const tragetView = ViewList.filter(view => view.uuid == uuid );
        // // 기존 뷰 연결을 끊습니다.
        if(this.viewSocket.connected&&this.state.view?.uuid){
            this.disconnectView(this.state.view?.uuid);
        }
        // 새롭게 뷰 객체를 연결합니다
        if(tragetView.length==1){
            this.setState({
                view : tragetView[0]
            },()=>this.initView())
        }
    }

    /** initView | Edit 페이지에서 View 소켓과 연결을 합니다. 
     * 
     * @returns 안녕
     */
    initView = () => {

        // 1. 이전에 연결된 View 소켓 통신이 있는지 확인합니다.

        if(!this.state.view){
            return
        }

        if(!this.state.view?.uuid){
            console.error(`⛔️ 선택한 뷰의 상태가 정상적이지 않습니다. 뷰 선택을 취소하고 소켓 연결을 캔슬합니다.`);
            this.setState({
                view : null
            },)
            return
        }
        this.viewSocket.emit('join', {
            uuid : this.state.view.uuid,
            accountToken : token('account'), 
        });
    }



    /** delete View | Edit 페이지에서 View 소켓과 연결을 합니다. 
     * 
     * @returns 안녕
     */
    disconnectView = (roomId) => {

        // 1. 소켓이 연결되어 있는지 확인합니다.
        if(this.viewSocket.connected){
            this.viewSocket.emit('leave',roomId);
            // this.viewSocket.disconnect();
        }
        // 연결이 끝나면 채널 캐쉬를 초기화 합니다.
        this.setState({
            channelCache: {},
        });
    }

    // TODO : 뷰 수정하도록 만들기 특히 브로드 케스트 관련 

    /** setView | 뷰를 수정합니다.
     * @param {object} modifiedData 수정할 데이터
     * @returns 오버랩을 생성합니다. 생성 성공시 생성한 오버랩의 아이디를 반환 합니다.
     *  */ 

    setView = (modifiedData,modifiedCache,modifyType) =>{
        console.log('뷰 재정의 ',modifiedData,modifiedCache,modifyType);

        if(modifiedCache){

        }
        // 연결된 뷰가 없으면 작동하지 않습니다.
        if(!this.state.view){
            return 
        }
        this.viewSocket.emit('set_view', {
            // 1번
            modifiedView : modifiedData,
            modifiedCache: modifiedCache,
            uuid : this.state.view.uuid,
            accountToken : token('account'), 
        });
    }




    ////////////////////////////////////////////////////////
    // 채널캐쉬 관련 (channelCache)
    ////////////////////////////////////////////////////////


    /**
     * 하나 대상의 채널 캐쉬를 수정합니다.
     * @param {*} modifiedData 추가할 채널 캐쉬
     * @param {*} identifier 대상의 UUID
     * @returns 
     */
    setChannelCache = (modifiedData,target,) => {
        const identifier =  (target.alias?`alias_${target.type}_${target.alias}`:`uuid_${target.type}_${target.uuid}`);
        const Cache = this.state.channelCache?.[identifier]  
        const modifiedCache = {...Cache,...modifiedData} 
        
        if(!this?.state?.view?.uuid){return}
        console.log('modifiedCache',modifiedCache,'this?.state?.view?.uuid',this?.state?.view?.uuid)
        
        this.viewSocket.emit('set_channelCache', {
            modifyCache : modifiedCache,
            viewID : this.state.view.uuid,
            widgetID : identifier,
            accountToken : token('account'), 
        });
    }

    resetChannelCache = (modifiedData,target,) =>{
        const identifier =  (target.alias?`alias_${target.type}_${target.alias}`:`uuid_${target.type}_${target.uuid}`);
        if(!this?.state?.view?.uuid){return}
        this.viewSocket.emit('set_channelCache', {
            modifyCache : modifiedData,
            viewID : this.state.view.uuid,
            widgetID : identifier,
            accountToken : token('account'), 
        });
    }

    /**
     * 캐쉬 데이터의 UUID 또는 alias를 변경합니다. 
     * @param {string} oldName 변경할 대상의 캐쉬
     * @param {string} newName 변경할 캐쉬
     * @returns 
     */
    reNameChannelCache = (oldName,newName) =>{
        if(!this?.state?.view?.uuid){return}
        this.viewSocket.emit('rename_channelCache', {
            oldID : oldName,
            newID : newName,
            viewID : this.state.view.uuid,
            accountToken : token('account'), 
        });
    }


    getChannelCache = (target) => {
        const identifier =  (target.alias?`alias_${target.type}_${target.alias}`:`uuid_${target.type}_${target.uuid}`);
        const Cache = this.state.channelCache?.[identifier]  
        if(Cache){
            return Cache;
        }else {
            return {}; 
        }
    }


    ////////////////////////////////////////////////////////
    // RTC 기능 관련 (Share)
    ////////////////////////////////////////////////////////
    /**
     * 쉐어 가능한 목록의 프리뷰 이미지를 소켓에 요청합니다.
     * @returns 
     */
    getShareProfile = () => {

        this.spaceSocket.emit('getShareList', {
            uuid : this.state.spaceUUID,
        });
        
        this.spaceSocket.emit('getShareProfile', {
            // 1번
            uuid : this.state.spaceUUID,
            accountToken : token('account'), 
        });
    }


    requestSharePeer = (widgetID,deviceID,shareID) => {

        if(deviceID && shareID){
            console.log('widgetId,deviceId,shareId',widgetID,deviceID,shareID)
            this.spaceSocket.emit('requestPeer', {
                // 1번
                uuid : this.state.spaceUUID,
                widgetID : widgetID,
                deviceID : deviceID, 
                shareID : shareID,
            });
        }
    }


    receiveOffer = (widgetID,deviceID,shareID) => {

        if(deviceID && shareID){
            console.log('widgetId,deviceId,shareId',widgetID,deviceID,shareID)
            this.spaceSocket.emit('requestPeer', {
                // 1번
                uuid : this.state.spaceUUID,
                widgetID : widgetID,
                deviceID : deviceID, 
                shareID : shareID,
            });
        }
    }

    /**
     * WEB RTC 통신을 위하여 SDP(answer)을 소켓을 통해 상대에게 전달합니다.
     * @param {string} widgetID : 발송하는 위젯의 ID
     * @param {string} deviceID : 상대 오버랩 디바이스의 ID
     * @param {string} shareID : 상대 공유 장치의 ID
     * @param {*} sdp : answer
     */
    sandAnswer = (widgetID,deviceID,shareID,sdp) => {
        if(deviceID && shareID){
            this.spaceSocket.emit('sandAnswer', {
                uuid : this.state.spaceUUID,
                widgetID : widgetID,
                deviceID : deviceID, 
                shareID : shareID,
                sdp
            });
        }
    }

    /**
     * WEB RTC 통신을 위하여 SDP(answer)을 소켓을 통해 상대에게 전달합니다.
     * @param {*} widgetID : 발송하는 위젯의 ID
     * @param {*} deviceID : 상대 오버랩 디바이스의 ID
     * @param {*} shareID : 상대 공유 장치의 ID
     * @param {*} sdp : answer
     */
    sendIce = (widgetID,deviceID,shareID,candidate) => {
        if(deviceID && shareID){
            this.spaceSocket.emit('sendIce', {
                uuid : this.state.spaceUUID,
                role : 'view',
                widgetID,
                deviceID, 
                shareID,
                candidate
            });
        }
    }


    ////////////////////////////////////////////////////////
    // 패널 관련
    ////////////////////////////////////////////////////////


    /** 편집창 하단 패널 의 컨텐츠를 조정합니다.
     * 
     * @param {*} target 조정할 대상 패널 [AppendPanel]
     * @param {*} state 패널의 상태
     */
    panelControl = (target,state,option={}) => {
        const modifyPanel = Utility.deepCopy(this.state.panel);


        switch (target) {
            case 'preferences':
                if(!state){
                    
                }else{
                    modifyPanel['preferences'] =  {state,option};
                }

            break;

            case 'utility':
                if(!state){
                    modifyPanel['utility'] =  {state:'widget',option};
                }else{
                    modifyPanel['utility'] =  {state,option};
                }
            break;

            case 'bottom':
                if(!state){
                    modifyPanel['bottom'] =  {state:'widget',option};
                }else{
                    modifyPanel['bottom'] =  {state,option};
                }
            break;
        
            default:
                break;
        }

        this.setState({
            panel :modifyPanel
        })


    }
    
    ////////////////////////////////////////////////////////
    // 클립보드 (initClipBoard)
    ////////////////////////////////////////////////////////

    /** initClipBoard | 클립보드 안에 key 값이 있는지 체크 하고 만약 키 값이 없으면 키를 포함해줍니다.
     * @param {object} key 클립보드 키 
     * @returns clipBoard 객체를 반합니다.
     *  */
    initClipBoard = (key) =>{
        let clipBoard = this.state.clipBoard;
            if (!clipBoard.hasOwnProperty(key)) {
                clipBoard[key] = []
            }
        return clipBoard 
    }
    /** appendClipBoard | 클립보드 내에서 
     * @param {object} modifyPage 오버랩의 페이지 파일
     * @returns 오버랩을 생성합니다. 생성 성공시 생성한 오버랩의 아이디를 반환 합니다.
     *  */ 
    appendClipBoard = (key,object) =>{
        let clipBoard = this.initClipBoard(key);

        console.log(`EditProvider : \n📥  ${key} 클립보드 안에 새 오브젝트가 추가되었습니다. \n ${key} 클립보드의 길이는 ${clipBoard[key].length} 입니다.` ,object);

        if (typeof key === 'string') {
            clipBoard[key].push(object);
     
            this.setState({
                clipBoard : clipBoard
            },()=>{console.log('clipBoard',this.state.clipBoard)})
            
        } else {
            console.error('변수가 문자열이 아닙니다.',key);
        }
    }

    /** deleteClipBoard | 클립보드 에서 해당 목록을 삭제합니다.
     * @param {object} modifyPage 오버랩의 페이지 파일
     * @returns 오버랩을 생성합니다. 생성 성공시 생성한 오버랩의 아이디를 반환 합니다.
     *  */ 

    deleteClipBoard = () =>{

    }

    /** initClipBoard | 클립보드 안에 key 값이 있는지 체크 하고 만약 키 값이 없으면 키를 포함해줍니다.
     * @param {object} key 클립보드 키 
     * @returns clipBoard 객체를 반합니다.
     *  */
     limitClipBoard = (clipBoard) =>{
        return clipBoard 
    }

    /** pasteClipBoard | 클립보드 안에 key 값이 있는지 체크 하고 만약 키 값이 없으면 키를 포함해줍니다.
     * @param {object} key 클립보드에서 붙여넣을 요소의 종류
     * @param {object} index 클립보드 리스트에서 붙여넣을 요소의 순서 1st : 0, 2nd : 1
     * @returns clipBoard 객체를 반합니다.
     *  */
    pasteClipBoard = (key,index=0) =>{
        let pasteObject = this.clipBoard?.[key]?.[index];
        return pasteObject 
    }

    ////////////////////////////////////////////////////////

    /** modifyLoaclPage | 서버에서 페이지를 받아 로컬 페이지를 갱신합니다.
     * @param {object} modifyPage 오버랩의 페이지 파일
     * @param {object} content 오버랩의 페이지 정의 파일
     * @returns 오버랩을 생성합니다. 생성 성공시 생성한 오버랩의 아이디를 반환 합니다.
     *  */ 
    modifyLoaclPage = (modifyPage,modifyContent) => {

        const content = this.state.overlap?.content;
        if(modifyContent){
            content['order'] = modifyContent?.order ? modifyContent.order :  content.order;
            content['asset'] = modifyContent?.asset ? modifyContent.asset :  content.asset;
        }
        const pageIndex = content.page.findIndex((value) => {
            return value.uuid == modifyPage.uuid;
          });

        if(pageIndex==-1){
            console.log('[페이지 추가] : 📩 서버로 부터 페이지를 받습니다. ',content.page);
            content.page.push(modifyPage);
            this.setState({
                overlap : {
                    ... this.state.overlap,
                    content : content
                }
            })
            return
        }
        console.log('[페이지 변경] : 📩 서버로 부터 페이지를 받습니다. ',pageIndex);

        content.page[pageIndex] = modifyPage

                
        this.setState({
            overlap : {
                ... this.state.overlap,
                content : content
            }
        },()=>{console.log('변경완료',content,modifyPage)})
        // let page = this.state.overlap?.content?.page;
        // let newPage = page.filter((value, index, arr) => {
        //     return value.uuid == modifyPage.uuid;
        // });
        // console.log('검증단계',page.uuid,newPage);
        // if(newPage.length==0){
        //     page.push(modifyPage);
        //     let newOverlap = this.state.overlap
        //     newOverlap.content['page'] =  newPage;
        //     this.setState({
        //         overlap : newOverlap
        //     })
        //     return
        // }
        // console.log(newPage);

    }
    
    /** selectPage | 페이지를 선택합니다.
     * @param {number} index 선택한 오버랩의 순서
     * @returns 오버랩을 생성합니다. 생성 성공시 생성한 오버랩의 아이디를 반환 합니다.
     *  */ 
    selectPage = (uuid) => {
        const index = this.state.overlap?.content?.page.findIndex(obj => obj.uuid === uuid);
        this.setState({
            selectPageUUID : uuid,
            selectPageIndex : index,
            selectWidget : null,
        })
    }
 
    /** modifySelectWidget | 페이지 내에서 선택된 위젯을 변경합니다. null을 받는경우는 아무것도 선택하지 않습니다.
     * @param {number|null} identifier  type 이 index 거나 null 인경우 위젯 index 넣기, uuid 인경우 uuid 넣기
     * @param {number|null} type 위젯을 uuid 기준으로 선택할것인지 id 기준으로 선택할것인지 선택합니다.
     * @returns 리턴은 없습니다. 
     *  */ 
    modifySelectWidget = (identifier = null,type='index') => {
        if(type=='index'){
            this.setState({
                selectWidget : identifier,
            })
        }else if(type=='uuid'){
            this.setState({
                selectWidget : identifier,
            })
        }
    }

    
    duplicationPage = (duplicationId)=>{
   
    }

    getPage = (pageUUid) => {
        if(pageUUid != null){
            const index = this.state.overlap?.content?.page.findIndex(page => page.uuid === pageUUid);
            return this.state.overlap?.content?.page[index];
        }
        return null

    }

    setPage = (pageid,uuid=this.state.uuid,id=this.state.overlapID,token=token('account')) => {

    }
    setIndex = (uuid=this.state.uuid,id=this.state.overlapID,token=token('account')) => {

    }
      
    editResolution = (zoom) =>{
        this.setState({
            editor : {
                ...this.state.editor,
                zoom : zoom,
            },
        })
    } 



    action = (action) =>{
        this.setState({
            action : action,
        })
    }

    ////////////////////////////////////////////////////////
    // 검증 (마우스)
    ////////////////////////////////////////////////////////

    mousePosition = (x,y) =>{
        
        // const zoom = this.state.editor.zoom  ;

        // const mouseX = Math.floor(x / zoom) ;
        // const mouseY = Math.floor(y / zoom) ;

        // this.setState({
        //     mouse : {x : mouseX,y : mouseY}
        // })

    }

    componentWillUnmount() {
        // 방을 나갑니다.
        console.log('소켓을 종료합니다.')
        // 오버랩 소켓 room 을 나갑니다.
        this.overlapSocket.emit('leaveRoom', this.props.uuid);
        // 오버랩소켓을 연결 해제 합니다.
        this.overlapSocket.disconnect();

        // 뷰소켓에서 연결된 room 이 있는경우 연결된 room 을 나갑니다.
        if(this.state.view){
            this.viewSocket.emit('leave',this.state.view.uuid);
        }
        // 뷰소켓을 연결 해제 합니다.
        this.viewSocket.disconnect();
    }
  
    render() {
      return (
        <EditContext.Provider value={this.state}> 
          {this.state.loading ? this.props.children :<EditerLoding/>}
        </EditContext.Provider>
      );
    }
  }

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}
    // export default Login;
    export default connect(mapStateToProps,mapDispatchToProps)(EditProvider);