////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';

import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결

// 이미지 연결
import logoW from 'assets/logoW.svg';
import PromotionVideo from 'assets/Promotion/overlapPromotion1.mp4';

// 컴포넌트 연결

import AccountBanner from 'components/Promotion/AccountBanner'; // 어카운트 관련 배너
// SASS&CSS 연결
import "sass/frontPage.scss"
// 서비스 연결
import * as Editor from 'service/edit/EditService' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


function ShareObject ({source,stream,streamID,stopShare,setPreview}) {

    const [devices, setDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState('');
    const videoRef = useRef(null);
    const canvasRef = useRef(null);

  
    const [previewImage, setPreviewImage] = useState(null);
    
    useEffect(() => {
        videoRef.current.srcObject = stream

        try {  
        const videoTrack = stream.getVideoTracks()[0];
            if (videoTrack) {
       
                const { width, height } = videoTrack.getSettings();
                console.log('init data')
                setTimeout(() => {
                    capturePreview()
                }, 2000);

                setInterval(() => {
                    // 여기에 실행하고자 하는 코드를 작성합니다.
                    capturePreview()
                  }, 100000); // 1000 밀리초 (100초)
   
              }
        } catch (error) {

        }
    }, [stream]);

    const capturePreview = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;

        // 제한 크기 설정
        const MAX_WIDTH = 300; // 최대 너비
        const MAX_HEIGHT = 300; // 최대 높이

        if (video && canvas && video.videoWidth > 0 && video.videoHeight > 0) {
            const context = canvas.getContext("2d");

            // 비디오의 원본 크기
            const videoWidth = video.videoWidth;
            const videoHeight = video.videoHeight;
        
            // 크기 비율 유지하면서 제한 크기에 맞추기
            let targetWidth = videoWidth;
            let targetHeight = videoHeight;
        
            if (videoWidth > MAX_WIDTH || videoHeight > MAX_HEIGHT) {
              const aspectRatio = videoWidth / videoHeight;
              if (videoWidth > videoHeight) {
                targetWidth = MAX_WIDTH;
                targetHeight = Math.round(MAX_WIDTH / aspectRatio);
              } else {
                targetHeight = MAX_HEIGHT;
                targetWidth = Math.round(MAX_HEIGHT * aspectRatio);
              }
            }

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            
            canvas.toBlob( (blob) => {
                  const compressedImageUrl = URL.createObjectURL(blob);
                //   console.log("Compressed Image URL:", compressedImageUrl);
                  console.log('imageDataURL',blob.size,);
                  // 미리보기 이미지 업데이트
                  setPreviewImage(compressedImageUrl);
                  setPreview(streamID,blob); // Save captured image
                //   setTimeout(() => {
                //     URL.revokeObjectURL(compressedImageUrl);
                //   }, 5000);
                },
            "image/jpeg",0.7 // 70% 품질
            );
        }
      };

      const video = videoRef?.current;

      const videoWidth = video?.videoWidth;
      const videoHeight = video?.videoHeight;

      console.log('source',source);
    return  (
    <Fragment>
    <style jsx>{`${ComponentStyle}`}</style>
        <div className='shareObject'>
        <canvas ref={canvasRef} style={{ display: "none" }}/>
        <video ref={videoRef} autoPlay/>  

        {/* {previewImage && (
          <img
            src={previewImage}
            alt="Webcam Preview"
            style={{ width: "300px", border: "1px solid #ccc" }}
          />
        )} */}

        {/* <textarea value={JSON.stringify(source)} /> */}

        <div className='shareObjectHover'>
            <div className='objectOption'>
           
            <div className='objectOptionList'>1</div>
              {videoWidth && videoHeight ? 
                <div className='resolution'>
                {videoWidth}x{videoHeight}
                </div>
              :null}

            </div>
            <nav className='objectNav'>
              <div>  
                {source?.shareLabel} 
              </div>
            </nav>
         
          
        </div>
        </div>
    </Fragment>
    );

}

export default ShareObject;



const ComponentStyle = `

.shareObject{
    display:flex;
    max-width : 480px;
    width : 100%;
    height : auto;
    background-color: #000;
    border-radius: 5px;
    position: relative;
    overflow : hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    
}

.shareObject video {
    width : 100%;
    height : 100%;
}

.shareObject textarea{
    width : 100%;
}
.shareObject .shareObjectHover {
    position: absolute;
    bottom : 0;
    display : flex;
    color : #fff;
    width : 100%;
    height : 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items : center;
    
}


.shareObject .shareObjectHover .objectOptionList{
    width : 60px;
    height : 20px;
    padding : 5px;
    margin : 5px;
    border-radius: 5px;
    opacity: 0.3;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #2F2F2F;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    transition: opacity 0.3s;
}

.shareObject:hover .shareObjectHover .objectOptionList{
    opacity: 1;
}
    

.shareObject .objectNav{
    display :flex;
    justify-content: center;
    align-items : center;

    width : 100%;
    overflow : hidden;
    height : 40px;
    font-size : 15px;
    background: rgba( 0, 0, 0, 0.3 );
    transition: background 0.3s;
}

.shareObject:hover .objectNav{
    background: rgba( 0, 0, 0, 0.7 );
   
}

.shareObject .objectOption{
    display :flex;
    justify-content: space-between;
    align-items : center;
    width : 100%;
    overflow : hidden;
    height : 40px;
    font-size : 15px;
    flex-direction: row;
}

.shareObject .objectOption .resolution{
    height : 20px;
    padding : 5px;
    margin : 5px;
    border-radius: 5px;
    background: rgba( 0, 0, 0, 0.7 );
}

.shareObject .shareObjectHover:hover {

}

`;