////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';
import YouTube from 'react-youtube';
import logoAnimate from 'assets/logoAnimate.svg';
// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// 서비스 연결
import * as config from 'config/OverlapConfig'
import * as Widget from 'service/edit/WidgetService' ; 
import * as fileAPI from 'service/api/file' ; 
import * as Utility from 'service/other/Utility'; 
import * as Editor from 'service/edit/EditService' ; 
import { widget } from 'service/value/Model';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function CameraV2({keyValue,pageKey,alias,type,key,id,uuid,getStyle,getAnimation,animation,parents,data}){

    ////////////////////////////////////////////////////////
    // Const
    ////////////////////////////////////////////////////////

    // set
    const WidgetRef = useRef(null);

    // const context = useContext(EditContext);

    /* 모든 위젯에서만 사용되는 고유 상수 */
    const Style = getStyle(data) // Style | 스타일을 가지고 옵니다.
    const Animation = getAnimation(animation,data) // Animation | 애니메이션을 가지고 옵니다.
    const MergeStyle = Widget.styleMerge(Style,Animation); // MergeStyle | 스타일과 애니메이션을 합칩니다.
    const context = useContext(EditContext);
    const peerConnection = useRef(null); // RTCPeerConnection 객체
    const iceCandidatesQueue = useRef([]); // RTCPeerConnection 객체
    const [connectState, setConnectState] = useState(null); // 상대방 오퍼 저장


    const WidgetActivate  = Widget.activate(data); // WidgetActivate | 위젯이 숨김상태인지 아닌지 판단합니다.
    const Width = data?.size?.width // Width | 위젯의 가로 사이즈
    const Height =data?.size?.height // Height | 위젯의 세로 사이즈
    const MinSize = Width<Height?Width:Height;
    /* 해당 위젯에서만 사용되는 고유 상수 */
    const MediaSource = data.value.mediaSource
    const MediaType = data.value.mediaType

    const [Progress, setProgress] = useState('load');
    const [ImageSource, setImageSource] = useState(null);
    const [PreviewSource, setPreviewSource] = useState(null);


    useEffect(() => {
   


        initCamera()
        return () => {
            cleanupCamera()
        };
    }, []);


    useEffect(() => {
        console.log('카메라 시작')
        ChangeSource()
        return () => {

        };
    }, [MediaSource]);

    useEffect(() => {


        if(context?.PeerList?.[uuid]?.timeStamp){
            console.log('context.PeerList',context?.PeerList?.[uuid]?.timeStamp,context.PeerList)
            const timeStamp = context.PeerList?.[uuid]?.timeStamp;
            const offer = context.PeerList?.[uuid]?.offer;
            const candidate = context.PeerList?.[uuid]?.candidate;
            const peerState = context.PeerList?.[uuid]?.peerState;
            if(offer && peerState == 'offer'){
                console.log('가져온 데이터 : ',timeStamp,offer)
                setOffer(offer)
                setConnectState('offer')
            } else if (candidate && peerState == 'ice'){
                console.log('가져온 데이터 ICE : ',timeStamp,candidate)
                getCandidate(candidate)
                setConnectState('LinkICE')
            } else if (peerState == 'fail') (
                setConnectState('standby')
            )
        }
        return () => {};
    }, [context?.PeerList?.[uuid]?.timeStamp]);


    useEffect(() => {
        console.log('소스 상황 변경',context?.sourceList)

        switch (connectState) {
            case 'standby':
                console.log('소스 상황 변경 스텐바이',context.sourceList)
                const Source = getSource(context.sourceList)
                console.log('소스 상황 변경 스텐바이',Source.shareState)
                if(Source.shareState == 'online'){
                    cleanupCamera()
                    initCamera()
                }
                // context.sourceList
                break;
        
            default:
                break;
        }

        return () => {

        };
    }, [context?.sourceList]);

    const initCamera = () => {
        if(parents == 'view'){
            return
        }

        setConnectState('initCamera')
        getCameraSource(MediaSource)
        // STUN 서버 설정 
        const configuration = config.RTCSERVERS;
        // RTCPeerConnection 초기화
        peerConnection.current = new RTCPeerConnection(configuration);
        
       
        peerConnection.current.onicecandidate = (event) => {
            if (event.candidate) {
                const sourceList = context?.sourceList
            if(!sourceList){return}
                const Source = getSource(context.sourceList)
                context.sendIce(uuid,Source?.deviceId,Source?.shareID,event.candidate)
            }
        };

        peerConnection.current.oniceconnectionstatechange = () => {
            console.log('ICE Connection State:', peerConnection.current.iceConnectionState);
    
            switch (peerConnection.current.iceConnectionState) {
              case 'connected':
                console.log('ICE 연결이 성공적으로 완료되었습니다.');
                // 연결 후 작업 수행
                
                startVideo();
                break;
              case 'disconnected':
                cleanupCamera();
                console.warn('ICE 연결이 끊어졌습니다.');
                setConnectState('standby')
                break;
              case 'failed':
                console.error('ICE 연결에 실패했습니다.');
                setConnectState('standby')
                break;
              case 'closed':
                console.log('ICE 연결이 닫혔습니다.');
                setConnectState('standby')
                break;
              default:
                break;
            }
          };
    
        // 트랙 처리 (예: 미디어 스트림 연결)
        peerConnection.current.ontrack = (event) => {
            if (WidgetRef.current) {
                WidgetRef.current.srcObject = event.streams[0];
              }
            // 이곳에서 원격 스트림을 처리할 수 있습니다.
        };
    }


    /**
   * 컴포넌트 언마운트 시 불리는 정리 함수
   */
    const cleanupCamera = () => {
        console.log('[CameraV2] cleanupRTC called.');
        // 1) 이벤트 핸들러 제거
        if (peerConnection.current) {
        peerConnection.current.onicecandidate = null;
        peerConnection.current.oniceconnectionstatechange = null;
        peerConnection.current.ontrack = null;

        // 2) 연결 해제
        peerConnection.current.close();
        peerConnection.current = null;
        }

        // 3) ICE 후보 큐 초기화
        iceCandidatesQueue.current = [];

        // 4) 비디오 태그 스트림 제거
        if (WidgetRef.current) {
        WidgetRef.current.srcObject = null;
        }
    };

    // 리프레쉬 
    const ChangeSource = async () => {
        cleanupCamera()
        initCamera()
    }

    /**
     * offer 받아 answer를 생성합니다.
     * @param {*} offer : 오퍼 객체 {type:sdp}
     * @returns 
     */
    const setOffer = async (offer) => {
        console.log('setOffer',offer)
        if(parents == 'view'){
            return
        }
        try {
            // 1. 오퍼를 Remote Description으로 설정
            await peerConnection.current.setRemoteDescription(
                new RTCSessionDescription(offer)
            );
            setConnectState('Description')
      
             // 2. Answer 생성
            const answer = await peerConnection.current.createAnswer();
            setConnectState('Answer')

                        // 3. 로컬 디스크립션 설정
            await peerConnection.current.setLocalDescription(answer);
            console.log("Local Description set successfully.");
            setConnectState('setDescription')

            // 앤서 전달
            const sourceList = context?.sourceList
            if(!sourceList){   
                setConnectState('setDescription | sourceList')
                return 
            }
            const Source = getSource(context.sourceList)
            context.sandAnswer(uuid,Source?.deviceId,Source?.shareID,answer)
            setConnectState('requestICE')
            setCandidate();

        } catch (error) {
            console.log("에러",error);
        }
    }

    const getCandidate = async (candidate) => {
        console.log('peerConnection.current',candidate,peerConnection.current)
        const IceCandidate = new RTCIceCandidate(candidate);
        iceCandidatesQueue.current.push(IceCandidate)
        setCandidate();
    }

    const setCandidate = async () => {
        const signalingState = peerConnection.current?.signalingState;
        if (["stable", "have-remote-offer"].includes(signalingState)) {
            if (iceCandidatesQueue.current && iceCandidatesQueue.current.length > 0) {
                while (iceCandidatesQueue.current.length > 0) {
                    const candidate = iceCandidatesQueue.current.shift();
                    try {
                      await peerConnection.current.addIceCandidate(candidate);
                      console.log("Added ICE Candidate:", candidate);
                    } catch (error) {
                      console.error("Error adding ICE Candidate:", error);
                    }
                  }
            }
        } else {
            console.warn("Cannot add ICE Candidates in the current signaling state:", signalingState);
        }


        // if (signalingState !== "stable" && signalingState !== "have-remote-offer") {
        //     while (iceCandidatesQueue.current.length > 0) {
        //         const candidate = iceCandidatesQueue.current.shift();
        //         await peerConnection.current.addIceCandidate(candidate);
        //       }
        // }
    }


      
    

    const getCameraSource = async (source) => {
        
        const sourceList = context?.sourceList
        if(!sourceList){   
            return 
        }
        const Source = getSource(context.sourceList,source)
        if(Source?.deviceId && Source?.shareID){
            setConnectState('requestPeer')
            console.log('requestSharePeer',uuid,Source?.deviceId,Source?.shareID)
            context.requestSharePeer(uuid,Source?.deviceId,Source?.shareID)
        }

    }    

    const startVideo = () => {
        setConnectState('complete')
        console.log('최종단계',peerConnection.current)
    }

    
    const getLoadInfo = () => {

        const sourceList = context?.sourceList

        if(!sourceList){
            return <div>서버 접속중</div>
        }
        const Source = sourceList.find(item => item.shareID === MediaSource);
        if(!Source){
            return <div>
                {Editor.icon({icon:'signal_wifi_statusbar_not_connected',size:MinSize/5,lineHeight:MinSize/5})}
            </div>
        }
        console.log('getLoadInfo',Source)
        return (
            <div style={{display:'flex',justifyContent:'center',justifyItems:'center',width:'100%',height:'100%'}}>
                <img src={logoAnimate} width={200} height={200}  alt="Logo Animation" />
                {/* <p>shareLabel:{Source?.shareLabel}</p>
                <p>socketID:{Source?.socketID}</p>
                <p>shareID:{Source?.shareID}</p>
                <p>deviceId:{Source?.deviceId}</p>  
                <p>state:{connectState}</p>  
                <p>iceConnectionState:{peerConnection.current?.iceConnectionState}</p>   */}

            </div>
        )

    }


    const SourceLoding = (width,height) => {

        const sourceList = context?.sourceList
        const Source = sourceList.find(item => item.shareID === MediaSource);

        if(!Source||Source?.shareState == "offline"){
            return <div className='cameraLoding'> {Editor.icon({icon:'signal_wifi_statusbar_not_connected',size:MinSize/5,lineHeight:MinSize/5})} <p style={{'fontSize': MinSize/20,'margin': MinSize/30,}} >카메라가 오프라인입니다.</p></div>
        }

        switch (connectState) {
            case 'initCamera':
                return <div className='cameraLoding'> <p>카메라가 초기화중 {uuid}</p></div>

            case 'complete':

                const video = WidgetRef?.current;

                const videoWidth = video?.videoWidth;
                const videoHeight = video?.videoHeight;
                return <div> {videoWidth} * {videoHeight}</div>
                    

        
            default:
                return <div className='cameraLoding'> {Editor.icon({icon:'signal_wifi_statusbar_not_connected',size:MinSize/5,lineHeight:MinSize/5})} <p style={{'fontSize': MinSize/20,'margin': MinSize/30,}} >{connectState} {uuid}</p></div>

        }
    }



    const getSource = (sourceList,tragetSource = MediaSource) => {
        const Source = sourceList.find(item => item.shareID === tragetSource);
        return Source
    }

    const getSourceState = (sourceList) => {
        const Source = sourceList.find(item => item.shareID === MediaSource);
        return Source
    }

    
    const GetCaemra = () => {

        return (
            <video ref={WidgetRef} className='object Camera' 
            style={{...MergeStyle.object}} autoPlay playsInline muted />
        );

    }

    ////////////////////////////////////////////////////////
    // Widget lander
    ////////////////////////////////////////////////////////
    switch (parents) {
        /* 편집화면에서 보이는 위젯의 모습 */
        case 'edit':
            // 편집화면에서는 애니메이션 효과를 비활성화 합니다.
            MergeStyle.layout.transition = false
            MergeStyle.object.transition = false

            if(MediaSource){
                return (
                    <Fragment>
                    <style jsx>{`${WidgetStyle}`}</style>
                    <div style={{...MergeStyle.layout}} className = {parents + " widgetObject "+type+WidgetActivate}>
                        <video ref={WidgetRef} className='object' 
                        style={{...MergeStyle.object}}
                        autoPlay
                        playsInline
                        muted
                        />
                        <div className='loadCamera'>
                            {SourceLoding(MergeStyle.object.width,MergeStyle.object.height)}
                        </div>
       
                    </div>
                    </Fragment>
            
                );
            }else{
                return (  
                    <Fragment>
                    <style jsx>{`${WidgetStyle}`}</style>
           
                    <div style={{...MergeStyle.layout}} className = {parents + " widgetObject "+type+WidgetActivate}>
                    <div className={"object unknown"} style={{...MergeStyle.object}}>
                    {MinSize < 200 ?
                        <Fragment>
                        {Editor.icon({icon:'no_photography',size:MinSize/2,lineHeight:MinSize/2})}
                
                        </Fragment>
                        :
                        <Fragment>
                        {Editor.icon({icon:'no_photography',size:MinSize/5,lineHeight:MinSize/5})}
                        <p style={{'fontSize': MinSize/20,'margin': MinSize/30,}}>
                            선택된 카메라 없습니다. <p>{MediaSource}</p> 
                        </p>
                        </Fragment>
                    }
                    </div>
                     
                    </div>
                    </Fragment>
                    );
            }
 
        /* 방송화면에서 보이는 위젯의 모습 */
        case 'broadcast':
            if(MediaSource){
                return (
                    <Fragment>
                    <style jsx>{`${WidgetStyle}`}</style>
                    <div style={{...MergeStyle.layout}} className = {parents + " widgetObject "+type+WidgetActivate}>
                        <video ref={WidgetRef} className='object Camera' 
                        style={{...MergeStyle.object}}
                        autoPlay
                        playsInline
                        muted
                        />
                        <div className='loadCamera'>
                            {/* {SourceLoding(MergeStyle.object.width,MergeStyle.object.height)} */}
                        </div>
       
                    </div>
                    </Fragment>
                )
            }else{
                return null;
            };
        /* 미리보기 화면에서 보이는 위젯의 모습 */
        case 'view':
            return (
                <Fragment>
                <style jsx>{`${WidgetStyle}`}</style>
                <div style={{...MergeStyle.layout}} className = {parents + " widgetObject "+type+WidgetActivate} >
                    <img className={'imageWrap imageProgress'+Progress}  src={PreviewSource} style={{...MergeStyle.object}}/>
                </div>
                </Fragment>
        
            );
        default:
            return (
                <Fragment>
                <style jsx>{`${WidgetStyle}`}</style>
                <div style={Style.layout} className = {parents + " widgetObject "+type+WidgetActivate} >
                    {/* {getYoutube()} */}
                </div>
                </Fragment>
            );

    }

}

export default CameraV2;


const WidgetStyle = `

.loadCamera {
    display: flex;
    position: absolute;
    width : 100%;
    height : 100%;
    justify-content: center;
    align-items: center;
}

.Camera .object{
    object-fit: cover;
    
    background-color: #000; 
    overflow: hidden;

}

.cameraLoding{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

`;

