////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , Profiler, createContext} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import _ from 'lodash';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"


// 컴포넌트 연결

// 서비스 연결

import * as Event from 'service/event/Actions' ; 
import * as Utility from'service/other/Utility.js' ; 
import * as viewAPI from 'service/api/view' ; 
import * as pageAPI from 'service/api/page' ; 
import * as spaceAPI from 'service/api/space' ; 
import { token } from 'service/event/Account'; 
import EditContext from 'service/context/EditContext';
import { widget } from 'service/value/Model';
import { object } from 'service/event/template';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

class ViewProvider extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        sidebar : 'Screen',
        view : null,
        pageList : [],
        loading : true,
        leave : false,
        cryptoIP : null,
        pageData : [],
        channelCache : {},
        sourceList : [],
        PeerList : {},
        sourcePreview : {},
        getChannelCache : this.getChannelCache,
        requestSharePeer : this.requestSharePeer,
        getShareProfile : this.getShareProfile,
        sandAnswer : this.sandAnswer,
        sendIce : this.sendIce,
    };
        this.spaceSocket  = spaceAPI.start();
        this.viewSocket  = viewAPI.start();
        this.pageSocket  = pageAPI.start();
    }

    async componentDidMount()  {

        // this.joinView(this.props.uuid)

        this.viewSocket.on('ready_view', () => {
            this.viewSocket.emit('request_view', {
                uuid : this.props.uuid,
                accountToken : token('account'), 
            });
        });

        this.viewSocket.on('receive_view', (viewData) => {
            console.log('갱신됨 : ',viewData,this.props.uuid);
            this.setState({
                view:viewData,
            })
        });

        this.viewSocket.on('receive_channelCache', (cache) =>  {
            this.setState({
                channelCache: cache,
            });
        });

        this.viewSocket.on('connect', (token) => {
            console.log(`📡 소켓 : View 소켓이 연결되었습니다, 연결된 뷰 : ${this.props.uuid} `);
            this.joinView(this.props.uuid)
        });

        this.pageSocket.on('ready_page', (uuid) => {
            console.log('[소켓 페이지] 페이지 시작',uuid)
            this.pageSocket.emit('request_page', {
                uuid : uuid,
                accountToken : token('account'), 
            }); 
        });

        this.pageSocket.on('response_page', (page) => {
            console.log('[소켓 페이지] response_page',page);
            // console.log('[소켓 페이지] 📩 서버로 부터 요청한 페이지를 받습니다.',page)
            this.updatePage(page)
        });


        this.pageSocket.on('refresh_page', (page) => {
            console.log('[소켓 페이지] refresh_page',page);

            // console.log('[소켓 페이지] : 📩 서버로 부터 갱신된 페이지를 받습니다. ',page);
            this.updatePage(page)
        });

        this.pageSocket.on('disconnect', (e) => {
            console.log('[소켓 페이지] : page 소켓 연결 중단',e);
            this.pageReloadControl(this.viewPointer(this.state?.view?.view_pointer))
          });

          this.pageSocket.on('connect', (e) => {
            console.log('[소켓 페이지] : page 소켓 연결 시작',e);
          });

          console.log('this.props.spaceUUID',this.props);
          this.spaceSocket.emit('join', {
                uuid : this.props.spaceUUID,
                deviceID : this.deviceID,
                accountToken : token('account'),  
            });
            
            /** 스페이스 소켓과 연결된 경우 초기화 정의 */
            this.spaceSocket.on('ready_space', (data) => {
                console.log('ready_space',data)
                this.spaceSocket.emit('getShareList', {
                    uuid : this.props.spaceUUID,
                });
            });

            this.spaceSocket.on('shareList', (data) => {
                console.log('sourceList',data)
                const ShareList  = data?.shareList??[];
                const cryptoIP  = data.cryptoIP;
                const result = [];
                for (const [deviceId, deviceInfoArray] of Object.entries(ShareList)) {
                    if (Array.isArray(deviceInfoArray)) {
                        deviceInfoArray.forEach(deviceInfo => {

                    
                        console.log('deviceInfo?.cryptoIP?.[0]',deviceInfo?.cryptoIP?.[0],cryptoIP)

                        result.push({
                            local : cryptoIP && cryptoIP == deviceInfo?.cryptoIP?.[0],
                            deviceId,
                            ...deviceInfo
                        });
                        });
                    } else {
                        console.error(`Expected an array but got ${typeof deviceInfoArray} for deviceId: ${deviceId}`);
                    }
                }

                this.setState({
                    sourceList : result,
                    cryptoIP : cryptoIP,
                })
            });

            this.spaceSocket.on('response_shareProfile', (data) => {
                this.setState({
                    sourcePreview : data
                })            
            });

            this.spaceSocket.on('receiveOffer', (data) => {
                const timeStamp = Date.now();
                const NewPeerState = {timeStamp,peerState: 'offer', offer: data.target.SDP, ice : null ,endPoint:data.endPoint}
                this.setState((prevState) => ({
                    PeerList: {
                    ...prevState.PeerList, // 기존 객체 복사
                    [data.target.widgetID]: NewPeerState,
                    },
                }));
            });

            this.spaceSocket.on('receiveIce', (data) => {
                const timeStamp = Date.now();
                const NewPeerState = {timeStamp,peerState: 'ice', offer: null, candidate : data.target.candidate ,endPoint:data.endPoint}
                this.setState((prevState) => ({
                    PeerList: {
                    ...prevState.PeerList, // 기존 객체 복사
                    [data.target.widgetID]: NewPeerState,
                    },
                }));
            });


    }

    componentDidUpdate(prevProps, prevState) {
        const prevList = this.viewPointer(prevState?.view?.view_pointer);
        const nextList = this.viewPointer(this.state?.view?.view_pointer);

        if (_.isEqual(prevList, nextList) == false) {
            this.pageDynamicControl(prevList, nextList);
        }
      }

    ////////////////////////////////////////////////////////
    // 소켓 연결 부분
    ////////////////////////////////////////////////////////

    /** joinView | View 소켓에 접속합니다.
     * @param {string} uuid 접속할 뷰의 아이디
     * @returns 
     *  */ 
    joinView = (uuid) => {
        this.viewSocket.emit('join', {
            uuid : uuid,
            accountToken : token('account'), 
            deviceID : null,
        });
    }
     /** leaveView | View 소켓에서 나갑니다.
     * @param {string} uuid 접속할 뷰의 아이디
     * @returns 
     *  */ 
    leaveView = (uuid) => {
        this.viewSocket.emit('leave', uuid);
    }
     /** updatePage | Page 소켓에서 페이지를 받아 뷰어에 적용시킵니다.
     * @param {object} page 오버랩의 페이지 객체
     * @returns 
     *  */ 
    updatePage = (page) =>{
        const pageUUID = page.uuid; 
        const prevPageData = this.state.pageData; 
        const viewPointer = this.state?.view?.view_pointer?.map(point => point.uuid);
        const nextPageData = prevPageData.filter(page => viewPointer.includes(page.uuid)&&pageUUID!=page.uuid);
        nextPageData.push(page)

        console.log('[소켓 페이지] viewPointer :',page)

        this.setState({
            pageData : nextPageData
        })
    }

    /** viewPointer | View 소켓에서 나옵니다..
     * @param {string} uuid 접속할 뷰의 아이디
     * @returns 
     *  */ 
    viewPointer = (pointerList) => {
        if(typeof(pointerList) == 'object'){
            const sortedPage = pointerList.sort((a, b) => a.order - b.order).map(item => item.uuid);
            return sortedPage;
        }else{
            return null;
        }
    }

    /** pageDynamicControl | 페이지 리스트가 변경되는 경우 
     * @param {string} uuid 접속할 뷰의 아이디
     * @returns 
     *  */ 
    pageDynamicControl = (prevPage,nextPage) =>{

        prevPage = prevPage??[];
        nextPage = nextPage??[];

        const includePage = nextPage.filter(page => !prevPage.includes(page));
        const removePage = prevPage.filter(page => !nextPage.includes(page));

        console.log('includePage',includePage)
        console.log('removePage',removePage)
        for (let index in includePage) {
            console.log('includePage',includePage[index]);
            this.includePage(includePage[index])
        }
        for (let index in removePage) {
            console.log('removePage',removePage[index]);
            this.removePage(removePage[index])
        }
        
    }

    pageReloadControl = (includePage) =>{
        for (let index in includePage) {
            console.log('includePage',includePage[index]);
            this.includePage(includePage[index])
        }
  
    }


    ////////////////////////////////////////////////////////
    // RTC 기능 관련 (Share)
    ////////////////////////////////////////////////////////
    /**
     * 쉐어 가능한 목록의 프리뷰 이미지를 소켓에 요청합니다.
     * @returns 
     */
    getShareProfile = () => {

        this.spaceSocket.emit('getShareList', {
            uuid : this.props.spaceUUID,
        });
        
        this.spaceSocket.emit('getShareProfile', {
            // 1번
            uuid : this.props.spaceUUID,
            accountToken : token('account'), 
        });
    }


    requestSharePeer = (widgetID,deviceID,shareID) => {

        if(deviceID && shareID){
            console.log('widgetId,deviceId,shareId',widgetID,deviceID,shareID)
            this.spaceSocket.emit('requestPeer', {
                // 1번
                uuid : this.props.spaceUUID,
                widgetID : widgetID,
                deviceID : deviceID, 
                shareID : shareID,
            });
        }
    }


    receiveOffer = (widgetID,deviceID,shareID) => {

        if(deviceID && shareID){
            console.log('widgetId,deviceId,shareId',widgetID,deviceID,shareID)
            this.spaceSocket.emit('requestPeer', {
                // 1번
                uuid : this.props.spaceUUID,
                widgetID : widgetID,
                deviceID : deviceID, 
                shareID : shareID,
            });
        }
    }

    /**
     * WEB RTC 통신을 위하여 SDP(answer)을 소켓을 통해 상대에게 전달합니다.
     * @param {string} widgetID : 발송하는 위젯의 ID
     * @param {string} deviceID : 상대 오버랩 디바이스의 ID
     * @param {string} shareID : 상대 공유 장치의 ID
     * @param {*} sdp : answer
     */
    sandAnswer = (widgetID,deviceID,shareID,sdp) => {
        if(deviceID && shareID){
            this.spaceSocket.emit('sandAnswer', {
                uuid : this.props.spaceUUID,
                widgetID : widgetID,
                deviceID : deviceID, 
                shareID : shareID,
                sdp
            });
        }
    }

    /**
     * WEB RTC 통신을 위하여 SDP(answer)을 소켓을 통해 상대에게 전달합니다.
     * @param {*} widgetID : 발송하는 위젯의 ID
     * @param {*} deviceID : 상대 오버랩 디바이스의 ID
     * @param {*} shareID : 상대 공유 장치의 ID
     * @param {*} sdp : answer
     */
    sendIce = (widgetID,deviceID,shareID,candidate) => {
        if(deviceID && shareID){
            this.spaceSocket.emit('sendIce', {
                uuid : this.props.spaceUUID,
                role : 'view',
                widgetID,
                deviceID, 
                shareID,
                candidate
            });
        }
    }


    ////////////////////////////////////////////////////////
    // 채널캐쉬 관련 (channelCache)
    ////////////////////////////////////////////////////////

    // setChannelCache = (modifiedData,uuid) =>{

    //     let modifyCache =  Utility.deepCopy(this.state.channelCache);

    //     modifyCache[uuid] = modifiedData;
        
    //     this.setState({
    //         channelCache : modifyCache
    //     },(()=>console.log('수정됨 캐쉬 :',this.state.channelCache)))
        
    // }

    getChannelCache = (target) => {
        const identifier =  (target.alias?`alias_${target.type}_${target.alias}`:`uuid_${target.type}_${target.uuid}`);
        const Cache = this.state.channelCache?.[identifier]  
        if(Cache){
            return Cache;
        }else {
            return {}; 
        }
    }
    

    /** includePage | Page 소켓에 접속합니다.
     * @param {string} uuid 접속할 뷰의 아이디
     * @returns 
     *  */ 
    includePage = (pageUUID) => {
        this.pageSocket.emit('join', {
            uuid : pageUUID,
            accountToken : token('account'), 
            deviceID : null,
        });
    }

    /** removePage | Page 소켓에서 나갑니다.
     * @param {string} pageUUID 접속할 뷰의 아이디
     * @returns 
     *  */ 
    removePage = (pageUUID) => {
        console.log('')
        this.pageSocket.emit('leave', pageUUID);
    }

    componentWillUnmount() {
        // 방을 나갑니다.
        console.log('소켓을 종료합니다.')
        this.leaveView(this.props.uuid)
        this.viewSocket.disconnect('unmonut');
        this.pageSocket.disconnect('unmonut');
    }
  
    render() {
        console.log('체크',this.props.children)
      return (
        <EditContext.Provider value={this.state}> 
          {this.state.loading ? this.props.children :<div>로딩입니다.</div>}
        </EditContext.Provider>
      );
    }
  }

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

    // export default Login;
    export default ViewProvider;