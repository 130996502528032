import store from "store";
import * as EnCoder from 'service/engine/EnCoder' ; 
import * as Event from 'service/event/Actions'; 
import * as Model from 'service/value/Model'; 
import * as Utility from 'service/other/Utility'; 
import { widget } from "service/value/Model";

// 아이콘
import logo from 'assets/logoW.svg';
////////////////////////////////////////////////////////
// WidgetEditTitle (components,option)
// 에딧페이지의 위젯내 에디트 타이틀을 생성해줍니다.
// 이는 state 안에 value값을 보관하고 있는경우 value 값을 갱신해줍니다.
////////////////////////////////////////////////////////

export function WidgetEditTitle(self,option) {

      const editOption = self?.props?.editOption? editOption : {};
      Object.assign(editOption,option)
      ////////////////////////////////////////////////////////
      // 구조 분해 할당 
      ////////////////////////////////////////////////////////
      const title = editOption.title!=undefined? editOption.title: self.props.type;
      const toggle = editOption.toggle!=undefined? editOption.toggle: true;
      const icon = editOption.icon!=undefined? <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont">{editOption.icon}</span>: <img className="NoneIcon" src={logo}/>;
      ////////////////////////////////////////////////////////
      // 토글 부분 
      ////////////////////////////////////////////////////////
      let togglePart = null
  
      if(toggle){
        // self.state.editorToggle 값이 없는 경우 에러 처리 
        const toggleState = self.state.editorToggle? self.state.editorToggle: false; 
        togglePart = <button className={'editorToggleButton'} onClick={()=>self.setState({editorToggle:!toggleState})}> <span className={toggleState?"editorToggle editorOpen":"editorToggle editorClose"}>▼</span></button> 
      }else{
        togglePart = null
      }

      return(
        <div className="EditTitle">
          <div className='titleContainer'>
            <div className="editorIcon" >
              {icon}
            </div>
            <p className="editorTitle">{title}</p>
          </div>
          <div className='EditerButtons'>
            {togglePart}
          </div>
        </div> 
      )
  }



export function editorDetail(title,icon,OpenForm,setOpenForm,useToggle,option) {

  // const editOption = self?.props?.editOption? editOption : {};
  // Object.assign(editOption,option)
  ////////////////////////////////////////////////////////
  // 구조 분해 할당 
  ////////////////////////////////////////////////////////
  // const title = editOption.title!=undefined? editOption.title: self.props.type;
  // const toggle = editOption.toggle!=undefined? editOption.toggle: true;
  // const icon = editOption.icon!=undefined? <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont">{editOption.icon}</span>: <img className="NoneIcon" src={logo}/>;
  ////////////////////////////////////////////////////////
  // 토글 부분 
  ////////////////////////////////////////////////////////

  if(option?.closeEdit){

  }

  let togglePart = null

  if(useToggle){
    // self.state.editorToggle 값이 없는 경우 에러 처리 
    togglePart = <button className={'editorToggleButton'} onClick={()=>setOpenForm(!OpenForm)}> <span className={OpenForm?"editorToggle editorOpen":"editorToggle editorClose"}>▼</span></button> 
  }else{
    togglePart = null
  }

  return(
    <div className="EditTitle">
      <div className='titleContainer'>
        <div className="editorIcon" >
          <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont">{icon}</span>
        </div>
        <p className="editorTitle">{title}</p>
      </div>
      <div className='optionContainer'>
        {option?.closeEdit?option.closeEdit:null }
        <div className='EditerButtons'>
        {togglePart}
      </div>
      </div>
    
    </div> 
  )
}


export function editorSubTitle(text,icon) {

  ////////////////////////////////////////////////////////
  // 구조 분해 할당 
  ////////////////////////////////////////////////////////
  // const text = option.text!=undefined? option.text: '';
  // const icon = option.icon!=undefined? <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont">{option.icon}</span>:  <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont">arrow_right</span>;

  return(
    <div className='SubHead'>
        <div className="editorIcon" >
        <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont">{icon}</span>
        </div>
        <p className="SubHeadText">{text}</p>
    </div> 
  )
}



////////////////////////////////////////////////////////
// WidgetEditSubhead (components,option)
// 에딧페이지의 위젯내 에디트 타이틀을 생성해줍니다.
// 이는 state 안에 value값을 보관하고 있는경우 value 값을 갱신해줍니다.
////////////////////////////////////////////////////////

export function WidgetEditSubhead(option) {

  ////////////////////////////////////////////////////////
  // 구조 분해 할당 
  ////////////////////////////////////////////////////////
  const text = option.text!=undefined? option.text: '';
  const icon = option.icon!=undefined? <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont">{option.icon}</span>:  <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont">arrow_right</span>;

  return(
    <div className='SubHead'>
        <div className="editorIcon" >
          {icon}
        </div>
        <p className="SubHeadText">{text}</p>
    </div> 
  )
}



////////////////////////////////////////////////////////
// WidgetEditSubhead (components,option)
// 에딧페이지의 위젯내 에디트 타이틀을 생성해줍니다.
// 이는 state 안에 value값을 보관하고 있는경우 value 값을 갱신해줍니다.
////////////////////////////////////////////////////////

export function WidgetEditImageText(option) {

  ////////////////////////////////////////////////////////
  // 구조 분해 할당 
  ////////////////////////////////////////////////////////
  const text = option.text!=undefined? option.text: '';
  const icon = option.icon!=undefined? <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont">{option.icon}</span>:  <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} class="material-symbols-outlined iconfont">arrow_right</span>;

  return(
    <div className='SubHead'>
        <div className="editorIcon" >
          {icon}
        </div>
        <p className="SubHeadText">{text}</p>
    </div> 
  )
}

////////////////////////////////////////////////////////
// icon (option)
/**
 * 이미지와 아이콘을 지정한 사이즈로 만들어줍니다.
 * ex : {icon:'아이콘이름',size:25,lineHeight:30}
 * icon type
 * @param {*} option.icon
 * @param {*} option.image
 * 
 * icon type
 * @param {*} option.size
 * @param {*} option.lineHeight
 * @param {*} option.color
 * 
 * @returns 
 */
////////////////////////////////////////////////////////


export function icon(option) {


  ////////////////////////////////////////////////////////
  // 구조 분해 할당     
  ////////////////////////////////////////////////////////
  const size = option.size!=undefined? option.size : 20; 
  const lineHeight = option.lineHeight!=undefined? option.lineHeight : 25; 
  const color = option.color!=undefined? option.color: '#ffffff'; 
  const iconStyle = {fontSize:`${size}px`,color,fontFamily:'Material Symbols Outlined',lineHeight:`${lineHeight}px`};
  const icon = <span style={iconStyle} >{option.icon!=undefined?option.icon:'expand_more'}</span>;
  const image = option.image!=undefined? <img src={option.image} style={{width:option.size,height:option.size,boxSizing:'border-box',padding:'2.5px'}} />: null;

  return(
      <div className="icon" >
        {image ? image :icon}
      </div>
  )
}



////////////////////////////////////////////////////////
// icon (option)
/**
 * 이미지와 아이콘을 지정한 사이즈로 만들어줍니다.
 * ex : {icon:'아이콘이름',size:25,lineHeight:30}
 * icon type
 * @param {*} option.icon
 * @param {*} option.image
 * 
 * icon type
 * @param {*} option.size
 * @param {*} option.lineHeight
 * @param {*} option.color
 * 
 * @returns 
 */
////////////////////////////////////////////////////////


export function textToHtml(richText) {
  let resHtml = ``;
  console.log('[T2] textToHtml 시점 ',richText);
  for (let index = 0; index < richText.length; index++) {
    console.log('[T2] richText 시점 ',richText[index]);
  if(richText[index].char.includes("\n")){

    resHtml = resHtml +  `<span  data-key=${index} key=${index} ><br/></span>`
  }else{
    resHtml = resHtml + `<span data-key=${index} key=${index} style="color: ${richText[index].style?.color??'black'}; font-size: ${richText[index].style?.size??'15px'};" >${richText[index].char}</span>`
  }}
  console.log('[T2] textToHtml 시점 ',resHtml);
  return resHtml
}


// export function convertHtmlToStructuredObject(html) {
//   const result = [];
//   let tempStr = "";
//   let dataKey = "";


  

//   // 정규식을 사용하여 태그를 처리
//   html.replace(/<[^>]*>/g, (match, offset, string) => {
//       if (match.includes("span")) {
//           tempStr = string.slice(offset + match.length).match(/^[^<]*/)[0]; // 다음 문자들 가져오기
//           const keyMatch = match.match(/data-key=['"]([^'"]+)['"]/);
//           dataKey = keyMatch ? keyMatch[1] : "";
//           result.push({ char: tempStr[0], data: dataKey });
//       } else if (match.includes("br")) {
//           result.push({ char: "\n", data: "" });
//       }
//   });

//   // 나머지 문자 처리
//   const remainingChars = html.replace(/<\/?[^>]+(>|$)/g, '');
//   for (let i = 0; i < remainingChars.length; i++) {
//       if (remainingChars[i] !== '\n') {
//           result.push({ char: remainingChars[i], data: "" });
//       }
//   }

//   return result;
// }

export function parseHtmlToObjects(html,defaultStyle,prevData) {


  html = Utility.deepCopy(html)

  html = html.replace('<span data-key=break ><br/></span>',`<span data-key=break >\n</span>`)


  html = html.replace('<div><br></div>',`<span data-key=break >\n</span>`)
  // html = html.replace('&nbsp;',`<span data-key=space > </span>`)

  const divTagPattern = /<div\b[^>]*>([\s\S]*?)<\/div>/gi;

  html = html.replace(divTagPattern, (match, p1) => {
    return `<span data-key=break >\n</span>` + p1 ;
});

  console.log('[T2]파서 divTagPattern 이후', html)
  // console.log('bytes',html,'\n',html3)
  const result = [];

  const spanTagPattern = /<span\b[^>]*>(.*?)<\/span>/gi;


  if(!spanTagPattern.test(html)){
    

  const encoder = new TextEncoder();
  const bytes = encoder.encode(html);
  console.log('[T2]파서 test 이후', html)
  // 바이트 배열을 다시 UTF-8 문자열로 변환
  const decoder = new TextDecoder('utf-8');
  const characters = decoder.decode(bytes).split('');
  console.log('[T2]파서 test 이후', characters)
    characters.map(text => {

      let style = defaultStyle??{}
      result.push(
        {
          char: text,
          style: style
        }
      )
    })
    return result;
  }

  // 텍스트 분석을 해야지 
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const spans = doc.querySelectorAll('span[data-key]');
  console.log('[T2]파서 Char', spans)
  Array.from(spans).map(span => {
    console.log('[T2]파서 Char', span.textContent)
    const Char = span.textContent!=''?span.textContent:'\n';

    for (let index = 0; index < Char.length; index++) {
      let style = defaultStyle??{}
      result.push(
        {
          char: Char[index],
          style: style
        }
      )
    }
  });
  return result;
}

