////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useRef, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";
// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
import TapNav from 'components/Edit/editPanel/preferences/editPreferences/EditTapNav';//오버레이
import TapEdit from 'components/Edit/editPanel/preferences/editPreferences/Tabs/TapEdit';//위젯 설정용 탭
import TapPreset from 'components/Edit/editPanel/preferences/editPreferences/Tabs/TabPreset';//위젯 프리셋 설정 탭
import TabAssets from 'components/Edit/editPanel/preferences/editPreferences/Tabs/TabAssets';//위젯 에셋 설정 탭
import TabAnimation from 'components/Edit/editPanel/preferences/editPreferences/Tabs/TabAnimation';//위젯 에셋 설정 탭

import TapDebug from 'components/Edit/editPanel/preferences/editPreferences/Tabs/TabDebug';//디버그용 탭
import TapDefault from 'components/Edit/editPanel/preferences/editPreferences/Tabs/TabDefault';//디버그용 탭

import TapTest from 'components/Edit/editPanel/preferences/editPreferences/Tabs/TapTest';//테스트용 탭
import PreviewList from 'components/Edit/editPanel/preferences/editPreferences/PreviewList';//오버레이
// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import TapWidget from './Tabs/TabWidget';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function EditPreferencesArea() {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set
    const context = useContext(EditContext);
    // state
    const [WidgetList, setWidgetList] = useState([]);
    

    /* EditContext 가 비동기 로딩으로 인해서 로딩중일때 오류를 막기 위한 State */
    const [ContextReady, setContextReady] = useState(false);
    const [PreferencesType, setPreferenceType] = useState('screen');

    const [isFlashing, setIsFlashing] = useState(false);


    // ref
    const PeferencesRef = useRef(null);
    const PreferencesTypeRef = useRef(null); // PreferencesType State 를 실시간으로 랜더 하기 위해서 
    const ScrollPositionRef = useRef({});
   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        const PeferencesElement = PeferencesRef.current;
        if (PeferencesElement) {
          PeferencesElement.addEventListener('scroll', handleScroll);
        }
    
        return () => {
          if (PeferencesElement) {
            PeferencesElement.removeEventListener('scroll', handleScroll);
          }
        };
      }, []);
    
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        if(context!=null){
            setContextReady(true);
        }
      }, [context]);
    
    
      const updateData = () => {
        setIsFlashing(true); // 데이터 업데이트 시 번쩍이는 효과 활성화
      };



      useEffect(() => {
        if (isFlashing) {
          const timer = setTimeout(() => setIsFlashing(false), 500); // 0.5s 후 번쩍이는 효과 해제
          return () => clearTimeout(timer); 
        }
      }, [isFlashing]);

      
      useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        if(context.selectWidget){
          setPreferenceType('edit')
          console.log('변경')
          updateData();

        }
      }, [context.selectWidget]);


            
      useEffect(() => {
        // state PreferencesType 값을 Ref 로 복사합니다. 
        // 이는 handleScroll (외부 연결 이벤트) 에 최신화 된 값을 보내기 위함힙니다.
        PreferencesTypeRef.current = PreferencesType;
        if(PreferencesType == 'screen'){
          PeferencesRef.current.scrollTop = ScrollPositionRef.current['screen']
        }
        return () => {

          };
      }, [PreferencesType]);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
  

      /**
       * 스크롤 위치를 저장합니다.
       */
      const handleScroll = () => {
        // HTML 컴포넌트가 로딩 되었는지 확인하고, PreferencesType이 스크린에 있다면 
        if (PeferencesRef.current && PreferencesTypeRef.current) {
          ScrollPositionRef.current[PreferencesTypeRef.current] = PeferencesRef.current.scrollTop;
        }
      };

    /**
     * 편집 프로바이더에서 위젯의 목록을 가저옵니다. 
     * 이후 위젯 리스트를 만들기 위해 데이터를 가공합니다.
     */
    const getWidgetList = () =>{
        if(ContextReady){
            const widgetList = context?.nowPage()?.page_widget;
            return widgetList?.slice()?.reverse()??[];
        }else{
            return []; 
        }
    }

    /**
     * 편집 프로바이더에서 오버랩의 위젯을 수정합니다.
     */
    const update = (modifyWidgetList) => {
        if(ContextReady){
            const widgetList = modifyWidgetList.slice().reverse();
            context?.modifyPage({'page_widget': widgetList}, 'modifyOrder');
        }
    };

    const setSelect = (uuid) => {
        if(ContextReady){
            context.modifySelectWidget(uuid);
        }
    };
    

    /**
     * 
     */
    const savedScrollPosition = (position) => {
 
    };
    /**
     * 설정 화면의 탭을 변경합니다.
     * @returns 
     */
    const preferences = () =>{
  



      switch (PreferencesType) {
        case 'screen':
          
          return <PreviewList context={context}/>
        case 'edit':
          return <TapWidget move={setPreferenceType}/>
        case 'preset':
          return <TapPreset move={setPreferenceType} />
        case 'asset':
          return <TabAssets move={setPreferenceType} />
        case 'animation':
          return <TabAnimation move={setPreferenceType} />
        case 'debug':
          return <Fragment> <TapDebug/> </Fragment>
        case 'test':
          return <TapEdit/>
        default :
          return <TapDefault/>
  
      }

    }

    const hideContext = () =>{

      if(context.contextMenu != null){
        context.hideContext()
      }
    }

    return (
          <Fragment>
          <TapNav PreferencesType={PreferencesType} setPreferenceType={setPreferenceType} />
          <style jsx>{`${EditPageStyle}`}</style>
          {/* <div id = "TapTop"/> */}
          {/* {PreferencesType} */}
          <div id = "TapArea" className={`data-display ${isFlashing ? 'flash-effect' : ''}`} ref={PeferencesRef} onClick={()=>hideContext()} contextMenu={()=>hideContext()} >
          {preferences()}
          </div>
          <div id = "TapTop"/>
          <div id = "TapBottom"/>
          </Fragment>

    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(EditPreferencesArea);
  


  const EditPageStyle = `

  #EditPreferencesArea{

  }
  #TapArea{
    display :flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    position : relative;

  }

  #TapArea::-webkit-scrollbar {
    width: 0px;
    height: 2px;
  }

  #TapArea&::-webkit-scrollbar-thumb {
    border-radius: 3.5px;
    background-color: #555;
    background-clip: padding-box;
    border: 1px solid transparent;
  }

  #TapArea::-webkit-scrollbar-track {
    background-color: #444;
  }

  #TapArea::-webkit-scrollbar-corner{

  }

  #TapTool{
    width: 100%;
    height: 20px;
    background-color: #3F3F3F;
    background: linear-gradient(to bottom, rgba(44,44,44,0), rgba(44,44,44,1));
  }
  #TapTop{
    width: 100%;
    height: 20px;
    position: absolute;
    top : 40px;
    background: linear-gradient(to top, rgba(63,63,63,0), rgba(63,63,63,0.8));
    pointer-events: none;
    backdrop-filter : blue(20px), saturate(20%);
  }
  #TapBottom{
    width: 100%;
    height: 20px;
    position: absolute;
    bottom : 0;
    background: linear-gradient(to bottom, rgba(44,44,44,0), rgba(44,44,44,1));
    pointer-events: none;
  }

  #WidgetSet{

    flex: 1 1;
    padding: 0px 5%;

  }




  /* styles.css */
@keyframes flash {
  from {
    opacity: 0; 
    transform: translate(-5%, 0%);
  }
  to {
    opacity: 1;
    transform: translate(0%, 0%);
  }
}

.flash-effect {
  animation-name: flash;
  animation-duration: 0.3s;
}

  
  `;






