import store from "store";
import * as Modal from './ModalControl';
import * as Utility from '../other/Utility' ; 
////////////////////////////////////////////////////////
// editPage init
////////////////////////////////////////////////////////
const guideLineDispatch = (line) => ({type:'guideLine',line})
const widgetShowDispatch = (state) => ({type:'widgetShow',state})
const editBackgroundDispatch = (mode,source) => ({type:'editBackground',mode,source})
const userStateDispatch = (state) => ({type:'userState',state})
////////////////////////////////////////////////////////
// editPage  
// 편집페이지 초기화
////////////////////////////////////////////////////////
export function editPage() {
    // const state = store.getState(); // 현재 상태를 가져옵니다.
    // const { editBackground, guideLine } = state; // 편의상 비구조화 할당
    userState()
    let guideLine = localStorage.getItem('GuideLine')
    let editBackground = JSON.parse(localStorage.getItem('EditBackground'))
    let widgetShow = JSON.parse(localStorage.getItem('WidgetShow'))
    let userguide = localStorage.getItem('UserGuide')
    if (guideLine!=undefined){
        store.dispatch(guideLineDispatch(guideLine))}
    if (editBackground!=undefined){store.dispatch(editBackgroundDispatch(editBackground.mode,editBackground.source))}
    if (widgetShow!=undefined){
        store.dispatch(widgetShowDispatch(widgetShow))}
    if (!userguide){
        // Modal.OverlayPush('EditTip')
        localStorage.setItem('UserGuide',true);
    }
    store.dispatch(widgetShowDispatch(widgetShow))
}

export function userState() {
    let userState = {
        browser : Utility.browserCheck()
    }
    store.dispatch(userStateDispatch(userState))
}
