////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , Profiler, createContext} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import _ from 'lodash';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"


// 컴포넌트 연결

// 서비스 연결
import * as deviceService from 'service/device/deviceService' ; 
import * as Utility from'service/other/Utility.js' ; 
import * as viewAPI from 'service/api/view' ; 
import * as spaceAPI from 'service/api/space' ; 
import * as pageAPI from 'service/api/page' ; 
import { token } from 'service/event/Account'; 
import ShareContext from 'service/context/ShareContext';
import { widget } from 'service/value/Model';
import { object } from 'service/event/template';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

class ShareProvider extends React.Component {
    constructor(props) {
      super(props);
      this.peerConnections = {};
      this.iceCandidatesQueue = {};
      this.state = {
        sourceList : {video : [], screen : []},
        screenList : [],
        streamList : {},
        profileList : {},
        peerConnections: {},
        apeendStream : this.apeendStream,
        removeStream : this.removeStream,
        screenShare : this.screenShare,
        setPreview : this.setPreview,
      };
      this.spaceSocket = spaceAPI.start();
      this.deviceID = deviceService.getID()
    }


    async componentDidMount()  {
        console.log('RTC 프로바이더 작동',this.props)
        this.spaceSocket.on('space', (token) => {
          console.log(`📡 소켓 : View 소켓이 연결되었습니다, 연결된 뷰 : ${this.props.uuid} `);
        });

        this.spaceSocket.on('responsePeer', (request) => {
          this.createOffer(request)
        });

        this.spaceSocket.on('receiveAnswer', (request) => {
          this.createICE(request)
        });

        this.spaceSocket.on('receiveAnswer', (request) => {
          this.createICE(request)
        });

        this.spaceSocket.on('receiveIce', (request) => {
          this.getCandidate(request)
      });
      
      this.initCamera();

      this.spaceSocket.emit('join', {
        uuid : this.props.spaceID,
        deviceID : this.deviceID,
        // accountToken : token('account'),  
    });
    }

    componentDidUpdate(prevProps, prevState) {
      const DeviceDetails = Utility.detectDeviceDetails()
    }
    
    componentWillUnmount() {
      // 컴포넌트 언마운트 시 클린업 수행
      this.cleanupResources();
    }

    ////////////////////////////////////////////////////////
    // 소켓 연결 부분
    ////////////////////////////////////////////////////////


    initProvider () {

    }


    /**
   * 전체 클린업 함수: 소켓, 미디어 스트림, PeerConnection 정리
   */
  cleanupResources = () => {
    // 1) 연결된 소켓 이벤트 해제 및 소켓 종료
    if (this.spaceSocket) {
      // 등록된 모든 소켓 이벤트를 off 할 수도 있고,
      // 각각 명시적으로 제거할 수도 있습니다.
      this.spaceSocket.removeAllListeners();
      this.spaceSocket.disconnect();
      console.log('소켓을 종료했습니다.');
    }

    // 2) 사용 중인 모든 MediaStream 중지
    Object.keys(this.state.streamList).forEach((shareId) => {
      const stream = this.state.streamList[shareId];
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    });

    // 3) PeerConnection 모두 닫기
    Object.keys(this.peerConnections).forEach((peerId) => {
      if (this.peerConnections[peerId]) {
        this.peerConnections[peerId].close();
      }
    });

    // 4) 필요하다면 iceCandidatesQueue 등도 초기화
    this.iceCandidatesQueue = {};
    
    console.log('모든 리소스를 정리하였습니다.');
  };


    initCamera = async () => {

      const videoDevices =  await this.getCamera()
      console.log('videoDevices',videoDevices);
      if(videoDevices?.length){
        for (let index = 0; index < videoDevices.length; index++) {
          const data = await this.apeendStream(videoDevices[index].shareID)
          videoDevices[index]['resolution'] = data?.['resolution']??{}
        }
        //비디오 디바이스를 가지고 옵니다.
        this.setState({
          sourceList : {screen : [...this.state.sourceList.screen],video : videoDevices}
        },()=>this.shareListUpdate())
      }
    }

    getCamera = async () => {
        try {
          // 디바이스 정보를 가지고 옵니다.
          const allDevices = await navigator.mediaDevices.enumerateDevices();

          // 비디오 디바이스만 필터링 합니다.
          const videoDevices = allDevices.filter(device => device.kind === 'videoinput');

          const videoList = videoDevices.map((device) => ({
            shareID : device.deviceId??'default',
            shareLabel : getLabel({label:device?.label}),
            resolution : null,
            shareType : device.kind,
            shareState : 'online',
            timeStamp : Date.now(),
          }))

          return videoList
        } catch (error) {
          console.error('디바이스를 가지고 오지 못했습니다. : ', error);
        }
    }
    
    shareListUpdate = async () => {

       
     console.log('this.state.sourceList.screen',this.state.sourceList);

      const screenList = this.state.sourceList.screen.map((screen) => ({
        shareID : screen.screenId??'default',
        shareLabel : '화면캡쳐',
        shareType : 'screen',
        shareState : 'online',
      }))

      const shareList = [...this.state.sourceList.video,...screenList]

      console.log('shareList',shareList);

      this.spaceSocket.emit('setShareList', {
          uuid : this.props.spaceID,
          shareList : shareList,
          deviceID : this.deviceID,
      });



    }



    screenShare =  async (screenid) => {
      try {
        const Stream = await navigator.mediaDevices.getDisplayMedia({
          video: { 
            cursor: 'never',
            width: { ideal: 1920 }, // 원하는 해상도 너비
            height: { ideal: 1080 }, // 원하는 해상도 높이
            frameRate: { ideal: 30 } // 선택 사항: 프레임 레이트 설정
          },
          audio: true // 선택 사항
        });
          // 스트림이 중지되었을 때 감지하는 이벤트 핸들러
          Stream.getVideoTracks()[0].onended = () => {
            console.log('사용자가 화면 공유를 중지했습니다.');
            alert('화면 공유가 중지되었습니다.');
        };

        // 또는 스트림 전체가 비활성화되었을 때 감지
        Stream.oninactive = () => {
            console.log('화면 공유 스트림이 중지되었습니다.');
            alert('화면 공유가 중지되었습니다.');
        };

        const MergedList = Object.assign({...this.state.streamList,[screenid]:Stream});
        this.setState((prevState) => ({
          streamList: MergedList
        }));
        
        this.setState((prevState) => ({
          sourceList : {  video : [...this.state.sourceList.video] , screen : [...this.state.sourceList.screen, { screenid: screenid }]}
        }));

      } catch (error) {
        console.error('Error accessing the selected camera: ', error);
      }
    }


    apeendStream = async (shareID) => {
      let videoTrack;
      let stream;
      console.log('shareID',shareID);
      try {
        // 1) 사용자 카메라 스트림을 획득
        stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: { exact: shareID } },
        });
    
        // 2) 비디오 트랙 및 기기 지원 해상도 정보 가져오기
        [videoTrack] = stream.getVideoTracks();
        console.log('videoTrack',videoTrack);
        const capabilities = videoTrack.getCapabilities();
    
        // 최대 해상도 및 최소 해상도 추출
        const maxWidth = capabilities.width?.max;
        const maxHeight = capabilities.height?.max;
        const minWidth = capabilities.width?.min;
        const minHeight = capabilities.height?.min;
    
        const outData = {
          resolution: { maxWidth, maxHeight, minWidth, minHeight },
        };
    
        console.log("Stream", stream);
        console.log("outData", outData);
    
        // 3) 최대 해상도가 존재하면 적용 시도
        if (maxWidth && maxHeight) {
          try {
            await videoTrack.applyConstraints({
              width: { exact: maxWidth },
              height: { exact: maxHeight },
            });
            console.log(`해상도가 ${maxWidth} x ${maxHeight}로 설정되었습니다.`);
          } catch (applyError) {
            console.error("최대 해상도 적용 오류:", applyError);
          }
        } else {
          console.warn("최대 해상도 정보를 가져올 수 없습니다.");
        }
    
        // 4) streamList에 새로운 스트림 추가
        const MergedStreamList = {
          ...this.state.streamList,
          [shareID]: stream,
        };
    
        this.setState(() => ({
          streamList: MergedStreamList,
        }));
    
        return outData;
      } catch (error) {
        console.error("선택한 카메라 접근 중 오류 발생:", error);
    
        // --- [오류 발생 시 해상도 640x480으로 재설정 시도] ---
        // 만약 videoTrack 자체가 없으면(= getUserMedia에서 실패)
        // applyConstraints를 적용할 수 없으므로 분기 처리 필요
        if (!videoTrack) {
          console.error("videoTrack이 없어 해상도 재설정에 실패했습니다.");
          return;
        }
    
        try {
          await videoTrack.applyConstraints({
            width: { exact: 640 },
            height: { exact: 480 },
          });
          console.log("해상도가 640 x 480으로 설정되었습니다.");
        } catch (applyError) {
          console.error("640 x 480 해상도 적용 중 오류 발생:", applyError);
        }
      }
    };
    

    removeStream = (shareId) => {
      if (this.state.streamList[shareId]) {
        this.state.streamList[shareId].getTracks().forEach((track) => {
          track.stop(); // 모든 트랙 중지
        });

        this.setState((prevState) => {
          const { [shareId]: _, ...newStreamList } = prevState.streamList; // shareId 키 삭제 후 나머지 복사
          return { streamList: newStreamList };
        });

        const filteredScreen = this.state.sourceList.screen.filter(item => item.screenid !== shareId);

        const filteredDevice = this.state.sourceList.video.filter(item => item.deviceId !== shareId);

        this.setState({
          sourceList : {video : filteredDevice, screen : filteredScreen}, 
        })

        
      }
    }

    setPreview = (streamID,previewImage) =>{

 
      this.setState({
        profileList : {...this.state.profileList,[streamID]:previewImage},
      },()=>{
        console.log('setPreview',streamID,previewImage,this.state.profileList);
        this.spaceSocket.emit('setShareProfile', {
          uuid : this.props.spaceID,
          profile : this.state.profileList,
          deviceID : this.deviceID,
      });

      })
    }
 
    /**
     * WebRTC 오퍼를 생성하는 함수
     * @param {RTCPeerConnection} peerConnection - WebRTC RTCPeerConnection 객체
     * @returns {Promise<RTCSessionDescriptionInit>} - 생성된 오퍼
     */
    createOffer = async (request) => {

      
      // 상수 정의
      const deviceID = request?.target?.deviceID??null // 이 컴퓨터 아이디
      const shareID = request?.target?.shareID??null // 쉐어할 기기 아이디 (ex 카메라, 화면, 오디오 등)

      const configuration = {
        iceServers: [
          {
            urls: "stun:stun.l.google.com:19302", // STUN 서버 URL
          },
        ],
      };

      if(!this.state?.streamList?.[shareID]){
        return
      }

      // this.state.sourceList.video.getTracks().forEach(track => {
      //   pc.addTrack(track, stream);
      // });

      const PeerID = `${request?.endPoint.widgetID}_${request?.target.shareID}`;
 
      this.peerConnections[PeerID] = new RTCPeerConnection(configuration);
  

      this.peerConnections[PeerID].statusCheck = {currentAnswer : false}
          
      this.peerConnections[PeerID].ontrack = (event) => {
        console.log("Remote track added:", event.streams[0]);
      };

      // 미디어 트랙을 PeerConnection에 추가
      // console.log('this.state.sourceList',this.state.streamList[shareID],request);
      this.state.streamList[shareID].getTracks().forEach((track) => {
        console.log("Adding track:", track);
        this.peerConnections[PeerID].addTrack(track, this.state.streamList[shareID]);
      });
      
      console.log("PeerConnection senders:", this.peerConnections[PeerID].getSenders());

      console.log("Stream for shareID:", this.state.streamList[shareID]);
      console.log("Tracks:", this.state.streamList[shareID]?.getTracks());


      console.log('RTC 상태 체크 Offer 시작:', PeerID,this.peerConnections[PeerID]);

      // DataChannel 생성 (offerer 측)
      // console.log('sourceList',this.state.sourceList)
      // const dataChannel = peerConnection2.createDataChannel('myChannel');

    
      this.peerConnections[PeerID].onicecandidate = (event) => {
        console.log('New ICE Candidate:');
        if (event.candidate) {
          this.spaceSocket.emit('sendIce', {
            uuid : this.props.spaceID,
            role : 'broadcast',
            socketID : request?.endPoint?.socketID,
            widgetID : request?.endPoint?.widgetID,
            candidate : event.candidate
          });
          console.log('New ICE Candidate:', event.candidate);
        } else {
          console.log('All ICE candidates have been gathered.');
        }
      };
      
      // Signaling 상태 디버깅
      this.peerConnections[PeerID].oniceconnectionstatechange = () => {
        console.log('ICE Connection State:', this.peerConnections[PeerID].iceConnectionState);

        switch (this.peerConnections[PeerID].iceConnectionState) {
          case 'completed':
            console.log('ICE 연결이 성공적으로 완료되었습니다.');
            // 연결 후 작업 수행
            // onConnectionEstablished();
            break;
          case 'disconnected':
            // 끊어진
            console.warn('ICE 연결이 끊어졌습니다.',PeerID,);
            console.log('ICE 연결이 끊어졌습니다.',  this.peerConnections);
            break;
          case 'faile d':
            console.error('ICE 연결에 실패했습니다.');
            break;
          case 'closed':
          
            console.log('ICE 연결이 닫혔습니다.');
            break;
          default:
            break;
        }
      };
      this.peerConnections[PeerID].onicegatheringstatechange = () => {
        console.log('ICE Gathering State:', this.peerConnections[PeerID].iceGatheringState);
      };

      try {
        if (!this.peerConnections[PeerID]) {
          console.error(`No PeerConnection found for Peer ID: ${PeerID}`);
          return;
        }
  
        const offer = await this.peerConnections[PeerID].createOffer();
        await this.peerConnections[PeerID].setLocalDescription(offer);

        console.log('RTC 상태 체크 Offer 종료:', PeerID,this.peerConnections[PeerID]);

        this.spaceSocket.emit('sendOffer', {
          uuid : this.props.spaceID,
          socketID : request?.endPoint?.socketID,
          widgetID : request?.endPoint?.widgetID,
          sdp : offer,
      });

        console.log(`Offer for Peer ${PeerID}:`, offer);
      } catch (error) {
        console.error(`Error creating offer for Peer ID ${PeerID}:`, error);
      }
    }


      /**
     * WebRTC 오퍼를 생성하는 함수
     * @param {RTCPeerConnection} peerConnection - WebRTC RTCPeerConnection 객체
     * @returns {Promise<RTCSessionDescriptionInit>} - 생성된 오퍼
     */
      createICE = async (request) => {

        console.log('request',request)

        try { 

          const PeerID = `${request?.endPoint?.widgetID}_${request?.target?.shareID}`;
          console.log('RTC 상태 체크 Answer 시작:', PeerID,this.peerConnections[PeerID]);
          if( request?.endPoint?.widgetID == undefined || request?.target?.shareID == undefined ){
            console.error("Invalid Answer PeerID:", PeerID);
            return;
          }

          const Answer = request?.target.SDP;

          if (!Answer || !Answer.type || !Answer.sdp) {
            console.error("Invalid Answer SDP:", Answer);
            return;
          }

          console.log('Answer',Answer,this.peerConnections[PeerID].signalingState)
          if (this.peerConnections[PeerID].signalingState === "have-local-offer") {
            // 1. Remote Description 설정
            if(this.peerConnections[PeerID]?.statusCheck?.currentAnswer == true){
              return;
            }
            this.peerConnections[PeerID].statusCheck['currentAnswer'] = true;
            if (this.peerConnections[PeerID].currentRemoteDescription) {
              console.log("Remote description is already set.");
              return;
            }
            await this.peerConnections[PeerID].setRemoteDescription(
              new RTCSessionDescription(Answer)
            );

            
            console.log('RTC 상태 체크 Answer 완료:',PeerID, this.peerConnections[PeerID]);
            console.log('Signaling State:', this.peerConnections[PeerID].signalingState);
            console.log("Remote Description set successfully.");
            this.setCandidate(PeerID);
          } else {
            console.error(
              "Invalid signaling state:",
              this.peerConnections[PeerID]?.signalingState
            );
          }
          
        } catch (error) {
          console.error("Error handling answer:", error);
        }
        
      }

      getCandidate = async (request) => {
        
        // ICE를 저장합니다.
        const PeerID = `${request?.endPoint.widgetID}_${request?.target.shareID}`;
        if (!this.iceCandidatesQueue[PeerID]) {
          this.iceCandidatesQueue[PeerID] = [];
        }
        const IceCandidate = new RTCIceCandidate(request?.target?.candidate);
        console.log(this.iceCandidatesQueue[PeerID])
        this.iceCandidatesQueue[PeerID].push(IceCandidate)
        this.setCandidate(PeerID);
      }

      setCandidate = async (peerID) => {
        const signalingState =  this.peerConnections[peerID].signalingState;
        console.log('signalingState',signalingState);
        if (["stable", "have-remote-offer"].includes(signalingState)) {
          if (this.iceCandidatesQueue[peerID] && this.iceCandidatesQueue[peerID].length > 0) {
            console.log("Processing ICE Candidates:", this.iceCandidatesQueue[peerID].length);
      
            while (this.iceCandidatesQueue[peerID].length > 0) {
              const candidate = this.iceCandidatesQueue[peerID].shift();
              try {
                await this.peerConnections[peerID].addIceCandidate(candidate);
                console.log("Added ICE Candidate:", candidate);
              } catch (error) {
                console.error("Error adding ICE Candidate:", error);
              }
            }
          }
        } else {
          console.warn("Cannot add ICE Candidates in the current signaling state:", signalingState);
        }
      }

  
    componentWillUnmount() {
        // 방을 나갑니다.
        console.log('소켓을 종료합니다.')
        this.spaceSocket.disconnect();
    }
  
    render() {

      return (
        <ShareContext.Provider value={this.state}> 
            {this.props.children}
        </ShareContext.Provider>
      );
    }
  }

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
export default ShareProvider;

function getLabel({label = '' }) {
  if(!label){
    const DeviceDetails = Utility.detectDeviceDetails()
    return DeviceDetails.operatingSystem+'카메라'
  }else{
    return label
  }
}

  