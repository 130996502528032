////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useContext, useRef, Fragment, Component } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useResizeDetector } from 'react-resize-detector';// 컴포넌트의 사이즈 감지
import GlobalContext from 'service/context/GlobalContext';
// 설정 파일
import * as config from 'config/OverlapConfig'

// [컴포넌트] 위젯 
// import MiniView from "components/viewer/MiniView"

// [리덕스]스토어 연결
// import store from "store";

// SASS&CSS 연결
// import 'sass/login.scss'

// 이미지 연결
// import logoW from 'assets/logoW.svg';


// 서비스 연결
import * as DeCoder from 'service/engine/DeCoder' ; 
import * as Editor from 'service/edit/EditService'; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/** 
 * 도움말을 생성합니다.
 * @param {Component} children 설명문이 나올 컴포넌트
 * @param {string} guideText 설명할 텍스트
 * @param {boolean} vertical 세로형태의 말풍선을 사용합니다.
 * @param {boolean} active 세로형태의 말풍선을 사용합니다.
 * @param {number} width 말풍선의 세로 크기를 지정합니다.
 * @returns 
 */


 function DynamicGuide() {
    
  
    ////////////////////////////////////////////////////////
    // Const
    ////////////////////////////////////////////////////////

    // set
    const context = useContext(GlobalContext);
    // ref
    const PointRef = useRef(null);
      



    if(!context?.guideBallon){
        return (null)
    }else{

      return(
        <Fragment>
        <div className={'DynamicGuide'} style={{
          // top : context?.guideBallon?.position?.y - context?.guideBallon?.position?.height,
          top : context?.guideBallon?.position?.y,
          left : context?.guideBallon?.position?.x,
          width : context?.guideBallon?.position?.width,
          height : context?.guideBallon?.position?.height,
          zIndex : 10,
        }} >
        <style jsx>{`${GuideStyle}`}</style>
         {/* <div className='BalloonTargetGuideM' style={{
          position: 'absolute',
          top: 0 ,
          left:0 ,

         }}>
          <div>
            <h2>안녕</h2>
            <p>아니요아니요아니요아니요아니요아니요</p>
          </div>
         </div> */}
        </div>
        </Fragment>
      )  
    }



  };

  export default DynamicGuide;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////

const GuideStyle = `
.DynamicGuide{
  position: absolute;
  display: flex;
  justify-content: center;
  pointer-events: none;
  border : 1px solid red;
}

.BalloonTargetGuideN{
  width : 200px;
  height : 50px;
  border-radius : 5px;
  padding : 5px;
  background: rgba( 0, 0, 0, 0.5 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 11px );
  -webkit-backdrop-filter: blur( 11px );
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.BalloonTargetGuideM{
  display :flex;
  background-color : rgb(18, 18, 18);
  background-image :linear-gradient(rgba(255, 255, 255, 0.051), rgba(255, 255, 255, 0.051));
  box-shadow : 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius : 5px;
  padding : 10px;
  /* 기본 가로 쓰기 방향 설정 */
  writing-mode: horizontal-tb;
  /* 텍스트가 한 줄로 표시되도록 설정 */
  white-space: nowrap;
  /* 자식 요소가 텍스트의 너비에 맞춰 크기가 조정되도록 설정 */
  display: inline-block;
  /* 필요에 따라 배경색이나 패딩을 추가할 수 있습니다 */
}


`;

